import { CommonModule, JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, EventEmitter, inject, Input, Output, signal } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { DateAdapter, MAT_DATE_LOCALE, provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule, TranslateService } from '@libs/shared/modules/i18n';

@Component({
	selector: 'merim-common-range-picker',
	standalone: true,
	imports: [CommonModule, MatFormFieldModule, MatDatepickerModule, FormsModule, ReactiveFormsModule, JsonPipe, TranslateModule, MatButton],
	templateUrl: './common-range-picker.component.html',
	styleUrl: './common-range-picker.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-EN' }, provideNativeDateAdapter()],
})
export class CommonRangePickerComponent {
	private readonly _translateService: TranslateService = inject(TranslateService);
	private readonly _adapter = inject<DateAdapter<unknown, unknown>>(DateAdapter);
	private readonly _locale = signal(inject<unknown>(MAT_DATE_LOCALE));

	@Input() range: FormGroup;
	@Output() applyClick = new EventEmitter<{ from: string; to: string }>();

	constructor() {
		effect(
			() => {
				this._locale.set(this._translateService.langChange());
				this._adapter.setLocale(this._locale());
			},
			{ allowSignalWrites: true }
		);
	}

	clickApply(from: string, to: string): void {
		this.applyClick.emit({ from, to: to === '' ? from : to });
	}
}
