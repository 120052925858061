import { PermissionsInfoModel } from '@libs/gap/models';
import { createReducer, on } from '@ngrx/store';

import { IFeaturePermissionsManagementState } from '../interfaces';
import { StorePermissions } from './actions';

export const initialState: IFeaturePermissionsManagementState = {
	data: {},
	loading: true,
};

export const reducer = createReducer(
	initialState,
	on(StorePermissions, (state, action) => {
		const permData = Object.keys(action.data).reduce((a, b) => {
			a[b] = new PermissionsInfoModel(action.data[b]);
			return a;
		}, {});

		return { ...state, data: permData, loading: false };
	})
);
