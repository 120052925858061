import { SalesChannelOption } from '@bk/price-management-common';
import { MapCoordinates } from '@libs/shared/modules/world-map';
import { BrandName } from '@merim/utils';
import { ENVIRONMENT_ENUM } from '@shared/models';
import { APP_WEB_ORDER_CHANNELS } from './enum';
import { IdentificationsModel } from './identifications.model';
import { PaymentMethodMetadata } from './payment-method-info.model';

export type ConfigType = {
	environment: ENVIRONMENT_ENUM;
	brand: BrandName;
	modules: ModulesType;
	API: APIType;
	dataLanguages: LanguagesType;
	common: CommonConfigurationsType;
};

/** MODULES DEFINITION **/
//#region
export enum MODULES {
	MONITORING = 'monitoring',
	ALLERGENS_MANAGEMENT = 'allergensManagement',
	RESTAURANT = 'restaurant',
	USER_MANAGEMENT = 'userManagement',
	DISCOUNT_MANAGEMENT = 'discountManagement',
	PRICE_MANAGEMENT = 'priceManagement',
	OWNER_MANAGEMENT = 'ownerManagement',
	PAYMENT_MANAGEMENT = 'paymentManagement',
	TAG_MANAGEMENT = 'tagManagement',
	MEDIA_MANAGEMENT = 'mediaManagement',
	OPTIONS_MANAGEMENT = 'optionsManagement',
}

export type ModulesType = {
	monitoring?: ModuleDefinition<MonitoringOptionsType>;
	restaurant?: ModuleDefinition<RestaurantOptionsType>;
	userManagement?: ModuleDefinition<undefined>;
	priceManagement?: ModuleDefinition<PriceOptionsType>;
	mediaManagement?: ModuleDefinition<MediaManagementOptions>;
	optionsManagement?: ModuleDefinition<undefined>;
	allergenManagement?: ModuleDefinition<undefined>;
	discountManagement?: ModuleDefinition<DiscountOptionsType>;
};

export type ModuleDefinition<T> = {
	enabled: boolean;
	values: Values;
	options: T;
};

export type Values = {
	restaurantIdentifications?: IdentificationsModel[];
	paymentMethodMetadatas?: PaymentMethodMetadata[];
	defaultAddressCoordinates?: MapCoordinates;
	webOrderChannels?: WebOrderChannels;
	requiredRestaurantAddress?: boolean
};

export type MediaManagementOptions = {
	config: MediaManagementConfig;
};

export type MediaManagementConfig = {
	inputFileExtensions: string;
	artifactInputFileExtensions: string;
};

export type RestaurantOptionsType = {
	[value in RESTAURANT_OPTIONS_VALUES]: OptionsValuesWithConfiguration;
};

export enum RESTAURANT_OPTIONS_VALUES {
	TPE = 'tpe',
	ZELTY = 'zelty',
	OPENING_TIMES = 'openingTimes',
	ENABLE_DEFERRED_PAY = 'enableDeferredPay',
	ENABLE_KINGDOM = 'enableKingdom',
	ENABLE_MULTI_PAY_ON_KIOSK = 'enableMultipayOnKiosk',
	ENABLE_ORB_DIGITAL = 'enableORBDigital',
	ENABLE_PAYMENT_CARTE_GR = 'enablePaymentCarteGR',
	WAIT_AT_THE_TABLE = 'enableWaitAtTheTable',
	TABLE_SERVICE = 'enableTableService',
	ENABLE_I_COUPON_PAY = 'enableICouponPay',
	TAGS = 'tags',
	OWNER_MANAGEMENT = 'ownerManagement',
	PAYMENT_MANAGEMENT = 'paymentManagement',
	TAG_MANAGEMENT = 'tagManagement',
	LOCAL_PRICE_CHANGE = 'localPriceChange',
	LOCAL_PRICE_CHANGE_DELIVERY = 'localPriceChangeDelivery',
	TABLE_SERVICE_QR_CODE = 'tableServiceQRCode',
}

export enum DISCOUNT_OPTIONS_VALUES {
	REF1 = 'ref1',
	REF2 = 'ref2',
	REF3 = 'ref3',
	REF4 = 'ref4'
}

export type DiscountOptionsValuesWithConfiguration = {
	required: boolean;
	propertyType: 'boolean' | 'string' | 'integer' | 'float';
	displayName: string;
	maxLength: number;
};

export type DiscountOptionsType = {
	[value in DISCOUNT_OPTIONS_VALUES]?: DiscountOptionsValuesWithConfiguration;
};

export type OptionsValues = {
	enabled: boolean;
};

export type OptionsValuesWithConfiguration = {
	enabled: boolean;
};

export type MonitoringOptionsType = {
	[value in MONITORING_OPTIONS_VALUES]: OptionsValues;
};

export enum MONITORING_OPTIONS_VALUES {
	MANAGER_LINK = 'managerLink',
	WEBREPORTING_LINK = 'webreportingLink',
	GUACAMOLE_LINK = 'guacamoleLink',
	LOGS_LINK = 'logsLink',
	WEB_ORDERS_LINK = 'webOrdersLink',
	DATA_UPDATE = 'dataUpdate',
	APP_UPDATE = 'appUpdate',
	RESTO_VPN_ADDRESS_SHOWN = 'restoVpnAddressShown',
	PLUTUS_FISCAL_LINK = 'plutusFiscalLink',
}

/** END OF MODULES DEFINITION **/
//#endregion

/** API DEFINITIONS **/
//#region
export type APIType = {
	[value in API]: ApiDefinition;
};

export enum API {
	GAP_BE = 'gap-be',
	DASH_BE = 'dash-be',
	IMAGE_SERVER_URL = 'image-server-url',
	GLOBAL_MANAGER_URL = 'global-manager-url',
	SAT = 'sat',
}

export type ApiDefinition = {
	url: string;
};
//#endregion
/** END OF API DEFINITIONS **/

/** LANGUAGES **/
//#region
export type LanguagesType = {
	available?: Array<LanguageType>;
	default: string;
};

export type LanguageType = {
	code: string;
	name: string;
};
//#endregion
/** END OF LANGUAGES **/

/** COMMON CONFIGURATIONS TYPE **/
//#region
export type CommonConfigurationsType = {
	referenceIds: CommonConfigurationsReferenceIds;
}

export type CommonConfigurationsReferenceIds = {
	ref1?: CommonConfigurationsReferenceId;
	ref2?: CommonConfigurationsReferenceId;
	ref3?: CommonConfigurationsReferenceId;
	ref4?: CommonConfigurationsReferenceId;
}

export type CommonConfigurationsReferenceId = {
	required?: boolean;
	propertyType?: CommonConfigurationsPropertyType;
	displayName: string;
	maxLength?: number;
}

export type CommonConfigurationsPropertyType = 'boolean' | 'string' | 'integer' | 'float';
export const CommonConfigurationsPropertyType = {
	Boolean: 'boolean' as CommonConfigurationsPropertyType,
	String: 'string' as CommonConfigurationsPropertyType,
	Integer: 'integer' as CommonConfigurationsPropertyType,
	Float: 'float' as CommonConfigurationsPropertyType
};

/** COMMON VALUES **/
//#region
export type PriceOptionsType = {
	allowedSalesChannelOptions: AllowedSalesChannelOption[];
	vat: Vat;
	priceLevels: PriceLevels;
	combinedRestaurantPrice: boolean;
};

export type AllowedSalesChannelOption = {
	key: SalesChannelOption;
};

export type WebOrderChannels = {
	values: APP_WEB_ORDER_CHANNELS[];
	showInRestaurants: boolean;
	showInUsers: boolean;
};

export type PriceLevels = {
	restaurant: PriceLevelValues<PRICE_LEVEL_RESTAURANTS>[];
	takeAway: PriceLevelValues<PRICE_LEVEL_TAKE_AWAY>[];
};

export enum PRICE_LEVEL_RESTAURANTS {
	Grille_A = 'Grille_A',
	Grille_B = 'Grille_B',
	Grille_C = 'Grille_C',
	Grille_D = 'Grille_D',
	Grille_E = 'Grille_E',
}

export enum PRICE_LEVEL_TAKE_AWAY {
	Grille_L1 = 'Grille_L1',
	Grille_L2 = 'Grille_L2',
	Grille_L3 = 'Grille_L3',
	Grille_L4 = 'Grille_L4',
}

export type PriceLevelValues<T> = {
	key: T;
	displayName: string;
};

export type Vat = {
	eatIn: VatValue[];
	takeAway: VatValue[];
};

export type VatValue = {
	key: VAT_VALUES;
	isDefault?: boolean;
};

export enum VAT_VALUES {
	ALCOHOL = 'Alcohol',
	REDUCED = 'Reduced',
	NORMAL = 'Normal',
	NO_VAT = 'NoVAT',
}

/** ENF OD COMMON VALUES **/
//#endregion
