import { createReducer, on } from '@ngrx/store';
import { IFeatureOptionsManagementState } from '../interfaces';
import {
	CreateOption,
	CreateOptionSuccess,
	DeleteOption,
	DeleteOptionSuccess,
	LoadSpecificOption,
	LoadSpecificOptionSuccess,
	LoadOptionsList,
	LoadOptionsListSuccess,
	RequestFailure,
	UpdateOption,
	UpdateOptionSuccess,
} from './actions';
import { lensPath, pipe, set } from 'ramda';


export const initialState: IFeatureOptionsManagementState = {
	data: [],
	loading: false,
};

export const reducer = createReducer(
	initialState,
	on(LoadOptionsList, (state) => {
		return <IFeatureOptionsManagementState>(
			set(lensPath(['loading']), true)(state)
		);
	}),
	on(LoadOptionsListSuccess, (state, action) => {
		return <IFeatureOptionsManagementState>(
			pipe(
				set(lensPath(['data']), action.data),
				set(lensPath(['loading']), false)
			)(state)
		);
	}),
	on(LoadSpecificOption, (state) => {
		return <IFeatureOptionsManagementState>(
			set(lensPath(['loading']), true)(state)
		);
	}),
	on(LoadSpecificOptionSuccess, (state, action) => {
		const isCurrentPresent = state.data.some((item) => item.id === action.id);
		let nextData = state.data?.length
			? isCurrentPresent
				? state.data.map((options) => (options.id === action.id ? { ...action.data, fullyLoaded: true } : { ...options }))
				: [...state.data, { ...action.data, fullyLoaded: true}]
			: [{ ...action.data, fullyLoaded: true }];

		return <IFeatureOptionsManagementState>(
			pipe(
				set(lensPath(['data']), nextData),
				set(lensPath(['loading']), false)
			)(state)
		);
	}),
	on(CreateOption, (state, action) => {
		return <IFeatureOptionsManagementState>(
			set(lensPath(['loading']), true)(state)
		);
	}),
	on(CreateOptionSuccess, (state, action) => {
		let nextData = [...state.data, { ...action.data, fullyLoaded: true}];
		return <IFeatureOptionsManagementState>(
			pipe(
				set(lensPath(['data']), nextData),
				set(lensPath(['loading']), false)
			)(state)
		);
	}),
	on(UpdateOption, (state, action) => {
		return <IFeatureOptionsManagementState>(
			set(lensPath(['loading']), true)(state)
		);
	}),
	on(UpdateOptionSuccess, (state, action) => {
		return <IFeatureOptionsManagementState>(
			set(lensPath(['loading']), false)(state)
		);
	}),
	on(DeleteOption, (state, action) => {
		return <IFeatureOptionsManagementState>(
			set(lensPath(['loading']), true)(state)
		);
	}),
	on(DeleteOptionSuccess, (state, action) => {
		return <IFeatureOptionsManagementState>(
			set(lensPath(['loading']), false)(state)
		);
	}),
	on(RequestFailure, (state, action) => {
		return <IFeatureOptionsManagementState>(
			set(lensPath(['loading']), false)(state)
		);
	})
);
