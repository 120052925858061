import { AppRestaurantInfoModel, ILoadSpecificRestaurantData } from '@libs/gap/models';
import { createAction, props } from '@ngrx/store';

export const LoadRestaurantList = createAction('[RestaurantManagement] Loading restaurant list');

export const LoadRestaurantListSuccess = createAction(
	'[RestaurantManagement] Loading restaurant list success',
	props<{
		data: AppRestaurantInfoModel[];
	}>()
);

export const LoadSpecificRestaurant = createAction('[RestaurantManagement] Loading specific restaurant', props<{ id: string }>());

export const LoadSpecificRestaurantSuccess = createAction(
	'[RestaurantManagement] Loading specific restaurant success',
	props<ILoadSpecificRestaurantData>()
);

export const CreateRestaurant = createAction('[RestaurantManagement] Create new restaurant', props<{ data: AppRestaurantInfoModel }>());

export const CreateRestaurantSuccess = createAction(
	'[RestaurantManagement] Create new restaurant success',
	props<{
		data: AppRestaurantInfoModel;
	}>()
);

export const UpdateRestaurant = createAction(
	'[RestaurantManagement] Updates specific restaurant',
	props<{
		id: string;
		data: AppRestaurantInfoModel;
	}>()
);

export const UpdateRestaurantSuccess = createAction(
	'[RestaurantManagement] Updates specific restaurant success',
	props<{
		id: string;
		data: AppRestaurantInfoModel;
	}>()
);

export const DeleteRestaurant = createAction('[RestaurantManagement] Delete specific restaurants', props<{ ids: string[] }>());

export const DeleteRestaurantSuccess = createAction('[RestaurantManagement] Delete specific restaurants success');

export const RequestFailure = createAction('[RestaurantManagement] Handle request error');
