import { Injectable, Optional, SkipSelf } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AdditionalSnackbarConfigurationType, SnackbarSubjectType } from './snack-bar-subject-type';
import { SnackBarConfig, SnackBarDesignType, SnackBarType } from './snack-bar-type';
import { generateUUID } from 'libs/shared/utils';

@Injectable({ providedIn: 'root' })
export class SnackbarService {
	// Explanation for this weird pattern:
	// I was not able to ensure that SnackbarService would be a Singleton
	// Despite many different strategies.
	// So now I am using "static property" as a workaround
	// We can now have multiple instances of SnackbarService created by DI, but they will all use the same static property
	snackbarSubject = new BehaviorSubject<SnackbarSubjectType>(null);
	snackbarState$ = this.snackbarSubject.asObservable();
	config: SnackBarConfig = {
		designType: "default"
	}

	constructor(@Optional() @SkipSelf() parentModule?: SnackbarService) {
		if (parentModule) {
			throw new Error('SnackbarService is already loaded. Import it in the AppModule only');
		}
	}

	initialize(config: SnackBarConfig) {
		this.config = config;
	}

	getObservable() {
		return this.snackbarState$;
	}

	show(
		title: string,
		type?: SnackBarType,
		dismissButtonText?: string,
		timeout?: number,
		onHideFunction?: () => void,
		additionalConfiguration?: AdditionalSnackbarConfigurationType
	): void {
		const id: string = generateUUID();

		const additionalConfigurationUpdated = {
			description: additionalConfiguration?.description,
			icon: additionalConfiguration?.icon,
			prefix: additionalConfiguration?.prefix,
			designType: additionalConfiguration?.designType || this.config.designType
		}
		this.snackbarSubject.next({
			id,
			show: true,
			title,
			type,
			dismissButtonText,
			onHideFunction,
			additionalConfiguration: additionalConfigurationUpdated,
		});

		if (timeout) {
			this.hide(id, timeout);
		}
	}

	hide(id: string, timeout?: number): void {
		this.snackbarSubject.next({
			id,
			show: false,
			timeout,
			onHideFunction: this.snackbarSubject.getValue()?.onHideFunction,
		});
	}

	hideAll(): void {
		this.snackbarSubject.next(null);
	}
}
