import { createReducer, on } from '@ngrx/store';
import { IFeatureTagManagementState } from '../interfaces';
import {
	CreateTag,
	CreateTagSuccess,
	DeleteTag,
	DeleteTagSuccess,
	LoadSpecificTag,
	LoadSpecificTagSuccess,
	LoadTagList,
	LoadTagListSuccess,
	RequestFailure,
	UpdateTag,
	UpdateTagSuccess,
} from './actions';

export const initialState: IFeatureTagManagementState = {
	data: [],
	loading: false,
};

export const reducer = createReducer(
	initialState,
	on(LoadTagList, (state) => {
		return { ...state, loading: true };
	}),
	on(LoadTagListSuccess, (state, action) => {
		return { ...state, data: action.data, loading: false };
	}),
	on(LoadSpecificTag, (state) => {
		return { ...state, loading: true };
	}),
	on(LoadSpecificTagSuccess, (state, action) => {
		let nextData = state.data?.length
			? state.data.map((tag) => (tag.id === action.id ? { ...action.data } : { ...tag }))
			: [{ ...action.data }];
		return { ...state, data: nextData, loading: false };
	}),
	on(CreateTag, (state, action) => {
		return { ...state, loading: true };
	}),
	on(CreateTagSuccess, (state, action) => {
		return { ...state, loading: false };
	}),
	on(UpdateTag, (state, action) => {
		return { ...state, loading: true };
	}),
	on(UpdateTagSuccess, (state, action) => {
		return { ...state, loading: false };
	}),
	on(DeleteTag, (state, action) => {
		return { ...state, loading: true };
	}),
	on(DeleteTagSuccess, (state, action) => {
		return { ...state, loading: false };
	}),
	on(RequestFailure, (state, action) => {
		return { ...state, loading: false };
	})
);
