import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IFeatureIngredientManagementState } from '../interfaces';

export const selectAppState = createFeatureSelector<IFeatureIngredientManagementState>('ingredient-management');

export const getIngredientListData = createSelector(selectAppState, (state) => state.data);

export const getSpecificIngredient = createSelector(selectAppState, (state, id: number) => state.data.find((ingredient) => ingredient.id === id));

export const isLoading = createSelector(selectAppState, (state) => state.loading);
