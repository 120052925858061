import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IFeatureDiscountManagementState } from '../interfaces';

export const selectAppState = createFeatureSelector<IFeatureDiscountManagementState>('discount-management');

export const getDiscountsList = createSelector(selectAppState, (state) => state.data);

export const getSpecificDiscount = createSelector(selectAppState, (state, id: number) => state.data.find((discount) => discount.id === id));

export const isLoading = createSelector(selectAppState, (state) => state.loading);
