<div class="breadcrumb-v2">
	@if (menuItems.length > 0) {
		<div class="material-symbols-outlined home">home</div>
		<div class="breadcrumb-v2__main_text flex items-center justify-center">
			<ng-container *ngTemplateOutlet="arrow"></ng-container>
		</div>
	}
	@for (item of menuItems; track $index) {
		<div class="breadcrumb-v2__main text-primary font-weight-700 text-base">
			@if ($index + 1 < menuItems.length) {
				<a
					[routerLink]="[item.url]"
					[ngClass]="{ 'breadcrumb-v2__main_bold': $index === 0 }"
					class="breadcrumb-v2__main_text !text-primary font-weight-500 hover"
				>
					{{ getName(item.label) | async }}
				</a>
				<div class="breadcrumb-v2__main_text flex items-center justify-center">
					<ng-container *ngTemplateOutlet="arrow"></ng-container>
				</div>
			}
			@if ($index + 1 === menuItems.length) {
				<a class="breadcrumb-v2__main_text pointer-events-none">
					{{ getName(item.label) | async }}
				</a>
			}
		</div>
	}
</div>

<ng-template #arrow>
	<svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16" fill="none">
		<path
			d="M0.821973 15.1156C0.653223 15.1156 0.512598 15.0593 0.371973 14.9468C0.118848 14.6937 0.118848 14.3 0.371973 14.0468L6.27822 7.99995L0.371973 1.9812C0.118848 1.72808 0.118848 1.33433 0.371973 1.0812C0.625098 0.828076 1.01885 0.828076 1.27197 1.0812L7.62822 7.54995C7.88135 7.80308 7.88135 8.19683 7.62822 8.44995L1.27197 14.9187C1.15947 15.0312 0.990723 15.1156 0.821973 15.1156Z"
			fill="#374151"
		/>
	</svg>
</ng-template>
