import { createReducer, on } from '@ngrx/store';
import { lensPath, pipe, set } from 'ramda';
import { IFeatureAllergenManagementState } from '../interfaces';

import { LoadAllergensInfo, LoadAllergensInfoSuccess, RequestFailure, UpdateAllergensInfo, UpdateAllergensInfoSuccess } from './actions';

export const initialState: IFeatureAllergenManagementState = {
	data: { info: { sysName: '', descriptionPrimary: {}, descriptionSecondary: {} } },
	loading: true,
};

export const reducer = createReducer(
	initialState,
	on(LoadAllergensInfo, (state) => {
		return <IFeatureAllergenManagementState>pipe(set(lensPath(['loading']), true))(state);
	}),
	on(LoadAllergensInfoSuccess, (state, action) => {
		return <IFeatureAllergenManagementState>pipe(set(lensPath(['data', 'info']), action.data), set(lensPath(['loading']), false))(state);
	}),
	on(UpdateAllergensInfo, (state, action) => {
		return <IFeatureAllergenManagementState>pipe(set(lensPath(['loading']), true))(state);
	}),
	on(UpdateAllergensInfoSuccess, (state, action) => {
		return <IFeatureAllergenManagementState>pipe(set(lensPath(['data', 'info']), action.data), set(lensPath(['loading']), false))(state);
	}),
	on(RequestFailure, (state, action) => {
		return { ...state, loading: false };
	})
);
