import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IAppState, IFeatureRootState } from '../interfaces';

export const selectAppState = createFeatureSelector<IFeatureRootState, IAppState>('app');

export const isOnline = createSelector(selectAppState, (state) => state.online);

export const isBackButtonHidden = createSelector(selectAppState, (state) => state.queryVariables?.hideBackButtons);

export const getSubMenu = createSelector(selectAppState, (state) => state.submenu);

export const getConfiguration = createSelector(selectAppState, (state) => state.configuration);
