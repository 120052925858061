import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ManifestService } from '@libs/shared/modules/frontend-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'gap-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class FooterComponent implements OnInit {
	version$: Observable<string>;

	constructor(private readonly manifestService: ManifestService) {}

	ngOnInit(): void {
		this.version$ = this.manifestService.readManifest('manifest.json').pipe(
			untilDestroyed(this),
			map((x) => {
				return x.version;
			})
		);
	}
}
