import { createReducer, on } from '@ngrx/store';
import { IFeatureDiscountManagementState } from '../interfaces';
import { lensPath, reject, propEq, pipe, set } from 'ramda';
import {
	DeleteDiscount,
	DeleteDiscountSuccess,
	LoadDiscounts,
	LoadDiscountsSuccess,
	LoadSpecificDiscount,
	LoadSpecificDiscountDraft,
	LoadSpecificDiscountSuccess,
	RequestFailure
} from './actions';

export const initialState: IFeatureDiscountManagementState = {
	data: [],
	loading: false,
};

export const reducer = createReducer(
	initialState,
	on(LoadDiscounts, (state) => {
		return <IFeatureDiscountManagementState>pipe(set(lensPath(['loading']), true))(state);
	}),
	on(LoadDiscountsSuccess, (state, action) => {
		return <IFeatureDiscountManagementState>pipe(set(lensPath(['data']), action.data), set(lensPath(['loading']), false))(state);
	}),
	on(LoadSpecificDiscount, (state) => {
		return <IFeatureDiscountManagementState>pipe(set(lensPath(['loading']), true))(state);
	}),
	on(LoadSpecificDiscountDraft, (state) => {
		return <IFeatureDiscountManagementState>pipe(set(lensPath(['loading']), true))(state);
	}),
	on(LoadSpecificDiscountSuccess, (state, action) => {
		const isCurrentPresent = state.data.some((discount) => discount.id === action.id);
		let nextData = state.data?.length
			? isCurrentPresent
				? state.data.map((discount) => (discount.id === action.id ? { ...action.data, fullyLoaded: true } : { ...discount }))
				: [...state.data, { ...action.data, fullyLoaded: true}]
			: [{ ...action.data, fullyLoaded: true }];

		return <IFeatureDiscountManagementState>(
			pipe(
				set(lensPath(['data']), nextData),
				set(lensPath(['loading']), false)
			)(state)
		);

	}),
	on(RequestFailure, (state, action) => {
		return <IFeatureDiscountManagementState>(
			set(lensPath(['loading']), false)(state)
		);
	}),
	on(DeleteDiscount, (state, action) => {
		return <IFeatureDiscountManagementState>pipe(set(lensPath(['loading']), true))(state);
	}),
	on(DeleteDiscountSuccess, (state, action) => {
		const newData = reject((discount) => discount.id === action.id, state.data);
		return <IFeatureDiscountManagementState>pipe(set(lensPath(['data']), newData), set(lensPath(['loading']), false))(state);
	})
);
