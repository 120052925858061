import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IFeatureProductManagementState } from '../interfaces';

export const selectAppState = createFeatureSelector<IFeatureProductManagementState>('product-management');

export const getProductListData = createSelector(selectAppState, (state) => state.productData);

export const getSpecificProduct = createSelector(selectAppState, (state, id: number) => state.productData.find((product) => product.id === id));

export const getProductFamilyListData = createSelector(selectAppState, (state) => state.productFamilyData);

export const getSpecificProductFamily = createSelector(selectAppState, (state, id: number) => state.productFamilyData.find((family) => family.id === id));

export const isLoadingProduct = createSelector(selectAppState, (state) => state.loading.loadingProductData);

export const isLoadingProductFamily = createSelector(selectAppState, (state) => state.loading.loadingProductFamilyData);
