<div class="breadcrumb">
	<merim-breadcrumb
		(breadcrumbs)="setBreadcrumbs($event)"
		[skippedBreadcrumb]="skippedBreadcrumb"
		[hiddenBreadcrumbUrl]="hiddenBreadcrumb"
	></merim-breadcrumb>
</div>

<div class="flex content-center items-center gap-2">
	<div class="relative">
		<div
			class="text-baseLayer-secondary relative flex h-12 w-12 cursor-pointer items-center justify-center"
			(click)="languageMenuTrigger.openMenu()"
		>
			<span class="material-icons">language</span>
			<div
				class="absolute bottom-[-8px] right-[0px] h-[1px] w-0"
				#languageMenuTrigger="matMenuTrigger"
				[matMenuTriggerFor]="languageMenu"
			></div>
		</div>
	</div>

	<div class="relative">
		<div
			(click)="profileMenuTrigger.openMenu()"
			class="border-neutralVariant-80 flex cursor-pointer content-between items-center gap-2 rounded-[100px] border border-solid px-4 py-[10px]"
		>
			<div class="logo !h-6 w-6"></div>
			<p class="line-height-[20px] text-accent-700 m-0">{{ userName() }}</p>
		</div>
		<div
			class="absolute bottom-[-8px] right-[0px] h-[1px] w-0"
			#profileMenuTrigger="matMenuTrigger"
			[matMenuTriggerFor]="profileMenu"
		></div>
	</div>
</div>

<mat-menu #languageMenu="matMenu" yPosition="below" class="language-selection">
	<merim-common-dropdown-menu>
		@for (lang of languageService.allAvailableLanguages; track lang) {
			<div
				mat-menu-item
				class="flex items-center"
				[ngClass]="{ '!bg-baseLayer-secondary !text-topLayer-secondary': languageService.currentLanguage === lang }"
				(click)="languageChange(lang)"
			>
				<span class="text-base leading-normal tracking-wide">{{ LANGUAGES_LONG[lang] }}</span>
			</div>
		}
	</merim-common-dropdown-menu>
</mat-menu>

<mat-menu #profileMenu="matMenu" yPosition="below" xPosition="after" class="settings-menu">
	<merim-common-dropdown-menu>
		<div class="flex flex-col gap-4">
			<div class="flex flex-col items-center gap-4">
				<div class="flex w-full justify-end"><span class="material-icons cursor-pointer">close</span></div>
				<div class="flex items-center justify-center">
					<div class="logo h-[88px] w-[88px]"></div>
				</div>
				<div class="flex flex-col">
					<div class="flex items-center justify-center text-2xl">{{ getFirstName() }}</div>
					<div class="flex items-center justify-center text-sm">{{ userEmail() }}</div>
				</div>
			</div>
			<div class="flex items-center justify-center gap-1 text-sm">
				@if (isSettingsAllowed()) {
					<div mat-menu-item class="flex-1 rounded-l-full !border !border-solid !border-black" (click)="openSettings()">
						<div class="flex items-center justify-center gap-2 p-3">
							<span class="material-icons text-h5 text-[18px]">settings</span>
							<span [translate]="'COMMON_SETTINGS'"></span>
						</div>
					</div>
				}
				<div
					mat-menu-item
					class="flex-1 rounded-r-full !border !border-solid !border-black"
					[ngClass]="{ 'rounded-full': !isSettingsAllowed() }"
					(click)="logout()"
				>
					<div class="flex items-center justify-center gap-2 p-3">
						<span class="material-icons text-[18px]">logout</span>
						<span [translate]="'COMMON_LOGOUT'"></span>
					</div>
				</div>
			</div>
		</div>
	</merim-common-dropdown-menu>
</mat-menu>
