import { AppMonitoringRestaurantsInfo, MonitoringOverallStatus, MonitoringRestaurantData } from '@libs/gap/models';
import { createAction, props } from '@ngrx/store';

export const LoadOverallStatus = createAction('[Monitoring] Loading overall status');

export const LoadOverallStatusSuccess = createAction(
	'[Monitoring] Loading overall status success',
	props<{ data: MonitoringOverallStatus }>()
);

export const LoadMonitoringRestaurantsInfo = createAction('[Monitoring] Loading restaurants Info');

export const LoadMonitoringRestaurantsInfoSuccess = createAction(
	'[Monitoring] Loading restaurants Info success',
	props<{ data: AppMonitoringRestaurantsInfo }>()
);

export const LoadSpecificMonitoringRestaurantInfo = createAction('[Monitoring] Loading specific restaurant info', props<{ id: string }>());

export const LoadSpecificMonitoringRestaurantInfoSuccess = createAction(
	'[Monitoring] Loading specific restaurant info success',
	props<{
		data: MonitoringRestaurantData;
	}>()
);

export const RequestFailure = createAction('[Monitoring] Handle request error');
