import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, signal, Signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Restaurant } from '@dki/api-client';
import { DatePeriod } from '@libs/dash/core/entity';
import {
	CommonLayoutSelectComponent,
	CommonLayoutTab,
	CommonLayoutTabsComponent,
	CommonScreenTitleComponent,
} from '@libs/shared/modules/common-components';
import { TranslateModule } from '@libs/shared/modules/i18n';
import { filter } from 'rxjs/operators';
import { DatePickerNewComponent } from '../date-picker-new/date-picker-new.component';
import {
	DashLayoutFilters,
	DashLayoutFiltersOptions,
	ExportOption,
	ExportOptionType,
	LayoutFilterService,
	LayoutRange,
} from '../layout-filter-service/layout-filter.service';
import { LayoutFiltersComponent } from '../layout-filters/layout-filters.component';
import { FinanceMovementTabComponent } from './partial-views/finance-movement-tab/finance-movement-tab.component';
import { GeneralSessionsTabComponent } from './partial-views/general-sessions-tab/general-sessions-tab.component';
import { PerSessionTabComponent } from './partial-views/per-session-tab/per-session-tab.component';
import { SafeDepositReportTabComponent } from './partial-views/safe-deposit-report-tab/safe-deposit-report-tab.component';

@Component({
	selector: 'finance-reports',
	standalone: true,
	imports: [
		CommonModule,
		CommonLayoutTabsComponent,
		CommonScreenTitleComponent,
		DatePickerNewComponent,
		LayoutFiltersComponent,
		CommonLayoutSelectComponent,
		TranslateModule,
		MatSlideToggleModule,
	],
	templateUrl: './finance-reports.component.html',
	styleUrl: './finance-reports.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanceReportsComponent implements OnInit {
	private readonly layoutFilterServiceService: LayoutFilterService = inject(LayoutFilterService);
	private readonly dr: DestroyRef = inject(DestroyRef);
	selectedTabId: string;

	get range(): Signal<LayoutRange> {
		return this.layoutFilterServiceService.range;
	}

	get rangePeriod(): Signal<DatePeriod> {
		return this.layoutFilterServiceService.rangePeriod;
	}

	get filters(): DashLayoutFilters {
		return this.layoutFilterServiceService.filters;
	}

	get filtersOptions(): DashLayoutFiltersOptions {
		return this.layoutFilterServiceService.filtersOptions;
	}

	tabs: CommonLayoutTab[] = [
		{
			label: 'history-logs-obj.receipts-synth',
			id: 'receipts',
			content: GeneralSessionsTabComponent,
		},
		{
			label: 'history-logs-obj.safe-deposit',
			id: 'safe-deposit',
			content: SafeDepositReportTabComponent,
		},
		{
			label: 'history-logs-obj.sessions-history',
			id: 'session',
			content: PerSessionTabComponent,
		},
		{
			label: 'history-logs-obj.financial-movements',
			id: 'financial',
			content: FinanceMovementTabComponent,
		},
	];

	ngOnInit(): void {
		this.initFilters();
		const availableExportOptions: ExportOption[] = [
			{ label: ExportOptionType.PDF, selected: false, type: ExportOptionType.PDF },
			{ label: ExportOptionType.XLSX, selected: false, type: ExportOptionType.XLSX },
		];
		this.layoutFilterServiceService.setAvailableExportOptions(availableExportOptions);
	}

	setRestaurants(restaurant: Restaurant | Restaurant[]) {
		this.layoutFilterServiceService.updateFilter('restaurant', restaurant);
	}

	onTabsChanged(event: string): void {
		this.selectedTabId = event;
	}

	onKiosksChange(event: boolean): void {
		this.layoutFilterServiceService.updateFilter('kiosks', event);
	}

	onFinancialChange(event: string | string[]): void {
		this.layoutFilterServiceService.updateFilter('financialReports', event);
	}

	onReceiptChange(event: string | string[]): void {
		this.layoutFilterServiceService.updateFilter('receipts', event);
	}

	onVendorsChange(event: string | string[]): void {
		this.layoutFilterServiceService.updateFilter('vendors', event);
	}

	onSafeDepositVendorsChange(event: string | string[]): void {
		this.layoutFilterServiceService.updateFilter('safeDepositVendors', event);
	}

	onDatePeriodSelection(period: DatePeriod) {
		this.layoutFilterServiceService.setRangePeriod(period);
	}

	onDateSelection(event: LayoutRange) {
		this.layoutFilterServiceService.setRange({ from: event.from, to: event.to });
	}

	exportData(selectedOptions: string[]) {
		this.layoutFilterServiceService.emitExport(selectedOptions);
	}

	private initFilters(): void {
		this.layoutFilterServiceService.registerFilter('restaurant', signal<Restaurant | Restaurant[]>([]));
		this.layoutFilterServiceService.registerFilter('kiosks', signal<boolean>(false));
		this.layoutFilterServiceService.registerFilter('financialReports', signal<string | string[]>([]));
		this.layoutFilterServiceService.registerFilter('receipts', signal<string | string[]>([]));
		this.layoutFilterServiceService.registerFilter('vendors', signal<string | string[]>([]));
		this.layoutFilterServiceService.registerFilter('safeDepositVendors', signal<string | string[]>([]));
	}
}
