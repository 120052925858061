import { createAction, props } from '@ngrx/store';
import { AppProductBaseInfo, AppProductFamily } from '@libs/gap/models';

export const LoadProductList = createAction('[ProductManagement] Loading Product list');

export const LoadProductListSuccess = createAction('[ProductManagement] Loading Product list success', props<{ data: AppProductBaseInfo[] }>());

export const LoadSpecificProduct = createAction('[ProductManagement] Loading specific Product', props<{ id: number }>());

export const LoadSpecificProductSuccess = createAction(
	'[ProductManagement] Loading specific Product success',
	props<{
		id: number;
		data: AppProductBaseInfo;
	}>()
);

export const LoadProductFamilyList = createAction('[ProductManagement] Loading Product family list');

export const LoadProductFamilyListSuccess = createAction('[ProductManagement] Loading Product family list success', props<{ data: AppProductFamily[] }>());

export const LoadSpecificProductFamily = createAction('[ProductManagement] Loading specific Product Family', props<{ id: number }>());

export const LoadSpecificProductFamilySuccess = createAction(
	'[ProductManagement] Loading specific Product Family success',
	props<{
		id: number;
		data: AppProductFamily;
	}>()
);

export const ProductRequestFailure = createAction('[ProductManagement] Handle product request error');
export const ProductFamilyRequestFailure = createAction('[ProductManagement] Handle product family request error');
