import { CurrencyPipe, KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import * as api from '@dki/api-client';
import { Restaurant } from '@dki/api-client';
import { getLocale } from '@libs/dash/core';

import { Range } from '@libs/dash/core/entity';
import { FeatureMapService } from '@libs/dash/guards';
import { generateXlsx, ReportOptions } from '@libs/dash/shared';
import { TranslateService } from '@libs/shared/modules/i18n';
import { UiVersionDetectorService } from '@libs/shared/services';
import { Worksheet } from 'exceljs';
import { jsPDF } from 'jspdf';
import { groupBy } from 'lodash-es';
import { DateTime } from 'luxon';
import { combineLatest, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { DTT_EXPORT_FACADE } from '../facade/dtt-export.facade.injection.token';
import { DttExportServiceProvider } from '../facade/dtt-export.facade.provider.interface';
import { SecondsToMins } from '../pipes/seconds-to-mins.pipe';
import { downloadCSV, exportBKCCSV, exportBKFCSV } from '../utils';

const GOALS = {
	mean_sos_ordertime: '00:45',
	mean_sos_servetime: '00:30',
	total: '02:45',
};

export enum SourceType {
	cod = '2',
	tablet = '4', // to be verified
	boths = 'both',
}

@Component({
	selector: 'dk-dtt-export',
	templateUrl: './dtt-export.component.html',
	styleUrls: ['./dtt-export.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DttExportComponent implements OnInit, OnDestroy {
	dateRange = new FormGroup({
		from: new FormControl(this.dttExportService.range.from.toJSDate()),
		to: new FormControl(this.dttExportService.range.to.toJSDate()),
	});

	private readonly newUiDetector: UiVersionDetectorService = inject(UiVersionDetectorService);
	isNewRoute = this.newUiDetector.isNewUi();

	goals = GOALS;
	dirtyForm = false;
	startedFetching = false;
	isExporting = false;

	weekDays = new Set<api.WeekDay>();
	data = [];
	sourceType: SourceType = SourceType.boths;
	i18n$ = this.translateService.selectTranslation('dtt-export');
	isLoading$ = this.dttExportService.isLoading$;
	isLoadingDTT$ = this.dttExportService.isLoadingDTT$;
	selectedRestaurant$ = this.dttExportService.myDefaultRestaurant$;
	selectedRestaurants$ = this.dttExportService.selectedRestaurants$;
	restaurant: Restaurant;
	selectedRestaurants: Array<Restaurant>;
	allAggregated = [
		api.DkiBeClickhouseAnalyticsSosDttReportAggregation.Restaurant,
		api.DkiBeClickhouseAnalyticsSosDttReportAggregation.Businessday,
	];
	restAggregated = [api.DkiBeClickhouseAnalyticsSosDttReportAggregation.Businessday];
	groupType: 'cumul_all' | 'cumul_res' = 'cumul_all';

	pipe = new SecondsToMins();
	currencyPipe = new CurrencyPipe(getLocale());

	destroy$: Subject<boolean> = new Subject<boolean>();

	isBKC = false;

	featureMap = this.fm.featureMap;

	sourced: boolean;

	restaurantNames: string;

	viewData$ = combineLatest([this.dttExportService.dttReport$, this.i18n$]).pipe(
		map(([dttReport, i18n]) => {
			this.viewData = { dttReport, i18n };
			if (this.multiSelection()) {
				const dttReportPerRestaurant = groupBy(dttReport, 'restaurant_id') as Record<string, api.SosDttReport[]>;
				this.restaurantNames = Object.keys(dttReportPerRestaurant)
					.map((id) => this.selectedRestaurants.find((rest) => rest.id === id).name)
					.join(', ');
				return { dttReport: dttReportPerRestaurant, i18n };
			}
			return { dttReport, i18n };
		})
	);

	viewData: {
		dttReport: api.SosDttReport[];
		i18n: Record<string, unknown>;
	};

	dayParts = [
		['7', '12'],
		['12', '14'],
		['14', '19'],
		['19', '21'],
		['21', '23'],
		['23', '4'],
		['4', '7'],
	];

	constructor(
		private translateService: TranslateService,
		@Inject(DTT_EXPORT_FACADE) private readonly dttExportService: DttExportServiceProvider,
		private fm: FeatureMapService,
		private ref: ChangeDetectorRef,
		private router: Router
	) {}

	ngOnInit(): void {
		this.dttExportService.switchMultiSeleciton(true);
		this.selectedRestaurants$
			.pipe(
				filter((restaurant) => !!restaurant),
				tap((restaurants) => {
					this.selectedRestaurants = restaurants;
				}),
				filter(() => this.startedFetching),
				takeUntil(this.destroy$)
			)
			.subscribe(() => this.fetchDTT());
		this.sourced = this.router.url.includes('sourced');
		if (this.sourced) {
			this.dttExportService.fetchRestaurants();
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
		this.dttExportService.switchMultiSeleciton(false);
	}

	setPeriod(period?: string) {
		const today = DateTime.now();
		let from = DateTime.fromJSDate(this.dateRange.controls.from.value),
			to = DateTime.fromJSDate(this.dateRange.controls.to.value);
		switch (period) {
			case Range.Today:
				from = today;
				to = today;
				break;
			case Range.Week:
				from = today.startOf(Range.Week);
				to = today.endOf(Range.Week);
				break;
			case Range.Month:
				from = today.startOf(Range.Month);
				to = today.endOf(Range.Month);
				break;
			case Range.Period:
				to = !this.dateRange.controls.to.value ? from : to;
				break;
		}
		this.dateRange.setValue({ from: from.toJSDate(), to: to.toJSDate() });
		this.dirtyForm = false;
		this.startedFetching = true;
		const aggregation = this.groupType === 'cumul_all' ? this.allAggregated : this.restAggregated;
		this.dttExportService.fetch(from, to, aggregation, Array.from(this.weekDays), SourceType[this.sourceType]);
	}

	selectDay(day, event: MouseEvent) {
		if (this.weekDays.has(day)) {
			this.weekDays.delete(day);
		} else {
			this.weekDays.add(day);
		}
		event.target['classList'].toggle('selected');
		this.dirtyForm = true;
	}

	fetchDTT() {
		const from = DateTime.fromJSDate(this.dateRange.controls.from.value),
			to = DateTime.fromJSDate(this.dateRange.controls.to.value);
		const aggregation = this.groupType === 'cumul_all' ? this.allAggregated : this.restAggregated;
		this.dttExportService.fetch(from, to, aggregation, Array.from(this.weekDays), SourceType[this.sourceType]);
		this.dirtyForm = false;
		this.startedFetching = true;
	}

	originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
		return 0;
	};

	setReportType(event: MatTabChangeEvent) {
		this.isBKC = event.index === 1;
	}

	sourceChanged(event) {
		this.dirtyForm = true;
	}

	singleDaySelection() {
		const from = this.dateRange.controls.from.value as Date;
		const to = this.dateRange.controls.to.value as Date;
		return from.getDate() === to.getDate() && from.getMonth() === to.getMonth() && from.getFullYear() === to.getFullYear();
	}

	multiSelection() {
		return this.selectedRestaurants?.length > 0 && this.groupType === 'cumul_res';
	}

	restaurantName(id) {
		return this.selectedRestaurants?.find((rest) => rest.id === id)?.name;
	}

	exportAllCSV(viewdata) {
		const footer = this.selectedRestaurants.length === 1 ? '' : this.selectedRestaurants.map((rest) => rest.name).join('_');
		const type = this.isBKC ? 'BKC' : 'BKF';
		const source_type = viewdata.i18n['source_types'][this.sourceType];
		let csv = '';
		if (!this.isBKC) {
			Object.entries(viewdata.dttReport).forEach(([restid, data]) => {
				const header = this.restaurantName(restid);
				let title = String(`SOS_DRIVE_${header}_${DateTime.fromJSDate(this.dateRange.value.from).toFormat('yyyy-MM-dd')}`);
				if (DateTime.fromJSDate(this.dateRange.value.from).toMillis() !== DateTime.fromJSDate(this.dateRange.value.to).toMillis()) {
					title += '_' + DateTime.fromJSDate(this.dateRange.value.to).toFormat('yyyy-MM-dd');
				}
				csv += exportBKFCSV(
					title,
					{
						dttReport: data,
						i18n: viewdata.i18n,
					},
					type,
					source_type,
					null,
					true,
					this.currencyPipe,
					this.pipe
				);
				csv += '\r\n\n';
			});
			csv += footer;
		} else {
			Object.entries(viewdata.dttReport).forEach(([restid, data]) => {
				const header = this.restaurantName(restid);
				let title = String(`SOS_DRIVE_${header}_${DateTime.fromJSDate(this.dateRange.value.from).toFormat('yyyy-MM-dd')}`);
				if (DateTime.fromJSDate(this.dateRange.value.from).toMillis() !== DateTime.fromJSDate(this.dateRange.value.to).toMillis()) {
					title += '_' + DateTime.fromJSDate(this.dateRange.value.to).toFormat('yyyy-MM-dd');
				}
				csv += exportBKCCSV(
					title,
					{
						dttReport: data,
						i18n: viewdata.i18n,
					},
					type,
					source_type,
					null,
					true,
					this.currencyPipe,
					this.pipe
				);
				csv += '\r\n\n';
			});
			csv += footer;
		}
		downloadCSV(csv, String(`SOS_DRIVE_Multi_Restaurant_${DateTime.fromJSDate(this.dateRange.value.from).toFormat('yyyy-MM-dd')}`));
	}

	async exportAllPDF(viewdata) {
		this.isExporting = true;
		const header = this.selectedRestaurants.length === 1 ? this.selectedRestaurants[0].name : 'Multi Restaurants';
		let title = String(`SOS_DRIVE_${header}_${DateTime.fromJSDate(this.dateRange.value.from).toFormat('yyyy-MM-dd')}`);
		if (DateTime.fromJSDate(this.dateRange.value.from).toMillis() !== DateTime.fromJSDate(this.dateRange.value.to).toMillis()) {
			title += '_' + DateTime.fromJSDate(this.dateRange.value.to).toFormat('yyyy-MM-dd');
		}
		const type = this.isBKC ? 'BKC' : 'BKF';
		const source_type = viewdata.i18n['source_types'][this.sourceType];
		// PDF code below
		const source = document.getElementById('multiple-dtt-report');
		const nodes = Array.prototype.filter.call(source.childNodes, (el) => {
			return el.getAttributeNames && !el.getAttributeNames().includes('hidden');
		});
		const doc = new jsPDF({ orientation: 'l', putOnlyUsedFonts: true });

		const from = DateTime.fromJSDate(this.dateRange.controls.from.value).setLocale(getLocale()).toFormat('yyyy LLL dd'),
			to = DateTime.fromJSDate(this.dateRange.controls.to.value).setLocale(getLocale()).toFormat('yyyy LLL dd');
		const date = from === to ? from : `${from} - ${to}`;
		doc.setFontSize(12);
		doc.setFont(undefined, 'light');
		doc.text(`${date}`, 20, 20);
		doc.text(`${source_type}`, 285, 20, { align: 'right' });

		let page = 0;
		for (const node of nodes) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			await doc.html(node, {
				html2canvas: {
					scale: 0.24,
					letterRendering: false,
					ignoreElements: (e) => e.classList.contains('export-buttons'),
				},
				margin: [30, 5, 5, 5],
				windowWidth: 1000,
				width: 900,
				fontFaces: [
					{
						family: 'Roboto',
						src: [
							{
								url: '/assets/fonts/roboto.ttf',
								format: 'truetype',
							},
						],
					},
				],
				y: page * 175,
				x: 15,
			});
			doc.setFontSize(12);
			doc.setFont(undefined, 'bold');
			doc.text(header, 20, 15);
			doc.text(type, 285, 15, { align: 'right' });
			doc.setFontSize(12);
			doc.setFont(undefined, 'light');
			doc.text(`${date}`, 20, 20);
			doc.text(`${source_type}`, 285, 20, { align: 'right' });
			doc.text(`${this.restaurantNames}`, 20, 200, { align: 'left' });
			page++;
		}

		doc.save(title);
		this.isExporting = false;
		this.ref.markForCheck();
	}

	exportXLSX(viewdata) {
		const reportOptions: ReportOptions = {
			setupHeaders: (sheet) => {
				const headers = this.isBKC ? this.getBKCHeaders(viewdata) : this.getBKFHeaders(viewdata);
				const headerRow = sheet.addRow(headers);
				headerRow.eachCell((cell) => {
					cell.font = { bold: true };
				});
				return headerRow;
			},
			prepareDataRows: (sheet) => {
				if (this.isBKC) {
					this.prepareBKCDataRows(sheet, viewdata);
				} else {
					this.prepareBKFDataRows(sheet, viewdata);
				}
			},
			generateFileName: () => {
				const from = DateTime.fromJSDate(this.dateRange.controls.from.value).toFormat('yyyy-MM-dd');
				const to = DateTime.fromJSDate(this.dateRange.controls.to.value).toFormat('yyyy-MM-dd');
				return `${this.isBKC ? 'BKC' : 'BKF'}_Report_${from}_${to}.xlsx`;
			},
		};
		const restaurantNames = this.selectedRestaurants.map((r) => r.name).join(', ');
		const detailText = `Restaurant${this.selectedRestaurants.length > 1 ? 's' : ''} : ${restaurantNames}\nPeriode :${this.dateRange.controls.from.value.toLocaleDateString()} - ${this.dateRange.controls.to.value.toLocaleDateString()}`;

		generateXlsx(this.isBKC ? 'Rapport BKC' : 'Rapport BKF', detailText, reportOptions);
	}

	private getBKCHeaders(viewdata): string[] {
		return [
			viewdata.i18n['dtt_report']['header']['day_part'],
			viewdata.i18n['dtt_report']['header']['ca'],
			viewdata.i18n['dtt_report']['header']['avrg_ticket'],
			viewdata.i18n['dtt_report']['header']['orders'],
			viewdata.i18n['dtt_report']['header']['cars'],
			viewdata.i18n['dtt_report']['header']['order'],
			viewdata.i18n['dtt_report']['header']['transit'],
			viewdata.i18n['dtt_report']['header']['delivery'],
			viewdata.i18n['dtt_report']['header']['total'],
		];
	}

	private getBKFHeaders(viewdata): string[] {
		return [
			viewdata.i18n['dtt_report']['header']['day_part'],
			viewdata.i18n['dtt_report']['header']['ca'],
			viewdata.i18n['dtt_report']['header']['avrg_ticket'],
			viewdata.i18n['dtt_report']['header']['orders'],
			viewdata.i18n['dtt_report']['header']['cars'],
			viewdata.i18n['dtt_report']['header']['wait'],
			viewdata.i18n['dtt_report']['header']['order_time'],
			viewdata.i18n['dtt_report']['header']['transit_delivery'],
			viewdata.i18n['dtt_report']['header']['delivery'],
			viewdata.i18n['dtt_report']['header']['total'],
			viewdata.i18n['dtt_report']['header']['park'],
			viewdata.i18n['dtt_report']['header']['depart'],
		];
	}

	private prepareBKFDataRows(sheet: Worksheet, viewdata: any) {
		if (Array.isArray(viewdata.dttReport)) {
			viewdata.dttReport.forEach((row: api.SosDttReport) => {
				const dayPart = viewdata.i18n['dtt_report']['day_part'][row.day_part];
				const ca = this.currencyPipe.transform(row.total_revenue, 'EUR');
				const avgTicket = this.currencyPipe.transform(row.total_revenue / row.count, 'EUR');
				const orders = row.count;
				const cars = row.count - row.sos_samecar;
				const waitTime = this.pipe.transform(row.mean_sos_waittime);
				const orderTime = this.pipe.transform(row.mean_sos_ordertime);
				const transitDeliveryTime = this.pipe.transform(row.mean_sos_linetime + row.mean_sos_paytime + row.mean_sos_hardservetime);
				const deliveryTime = this.pipe.transform(row.mean_sos_deliverytime);
				const totalTime = this.pipe.transform(this.totalTimeBKF(row));
				const parkTime = this.pipe.transform(row.mean_sos_parktime);
				const departTime = this.pipe.transform(row.mean_sos_departtime);

				sheet.addRow([
					dayPart,
					ca,
					avgTicket,
					orders,
					cars,
					waitTime,
					orderTime,
					transitDeliveryTime,
					deliveryTime,
					totalTime,
					parkTime,
					departTime,
				]);
			});

			const totalRow = this.calculateTotalRowBKF(viewdata.dttReport);
			sheet.addRow(totalRow);
		} else {
			console.error('viewdata.dttReport is not an array:', viewdata.dttReport);
		}
	}

	private totalTimeBKF(row: api.SosDttReport): number {
		return (
			(row.mean_sos_waittime || 0) +
			(row.mean_sos_ordertime || 0) +
			(row.mean_sos_linetime || 0) +
			(row.mean_sos_paytime || 0) +
			(row.mean_sos_hardservetime || 0) +
			(row.mean_sos_deliverytime || 0) +
			(row.mean_sos_parktime || 0) +
			(row.mean_sos_departtime || 0)
		);
	}

	private calculateTotalRowBKF(data: api.SosDttReport[]): any[] {
		const totalCA = this.total(data, 'total_revenue');
		const totalOrders = this.total(data, 'count');
		const totalCars = totalOrders - this.total(data, 'sos_samecar');

		const weightedAverage = (key: string) => {
			return data.reduce((sum, row) => sum + (row[key] || 0) * row.count, 0) / totalOrders;
		};

		const avgWaitTime = weightedAverage('mean_sos_waittime');
		const avgOrderTime = weightedAverage('mean_sos_ordertime');
		const avgTransitDeliveryTime =
			weightedAverage('mean_sos_linetime') + weightedAverage('mean_sos_paytime') + weightedAverage('mean_sos_hardservetime');
		const avgDeliveryTime = weightedAverage('mean_sos_deliverytime');
		const avgTotalTime = data.reduce((sum, row) => sum + this.totalTimeBKF(row) * row.count, 0) / totalOrders;
		const avgParkTime = weightedAverage('mean_sos_parktime');
		const avgDepartTime = weightedAverage('mean_sos_departtime');

		return [
			this.viewData.i18n['dtt_report']['day_part']['avrg_day'],
			this.currencyPipe.transform(totalCA, 'EUR'),
			this.currencyPipe.transform(totalCA / totalOrders, 'EUR'),
			totalOrders,
			totalCars,
			this.pipe.transform(avgWaitTime),
			this.pipe.transform(avgOrderTime),
			this.pipe.transform(avgTransitDeliveryTime),
			this.pipe.transform(avgDeliveryTime),
			this.pipe.transform(avgTotalTime),
			this.pipe.transform(avgParkTime),
			this.pipe.transform(avgDepartTime),
		];
	}

	private total(data: api.SosDttReport[], key: string): number {
		return data.reduce((sum, item) => sum + (item[key] || 0), 0);
	}

	private prepareBKCDataRows(sheet: Worksheet, viewdata: any) {
		if (Array.isArray(viewdata.dttReport)) {
			viewdata.dttReport.forEach((row: api.SosDttReport) => {
				const dayPart = viewdata.i18n['dtt_report']['day_part'][row.day_part];
				const ca = this.currencyPipe.transform(row.total_revenue, 'EUR');
				const avgTicket = this.currencyPipe.transform(row.total_revenue / row.count, 'EUR');
				const orders = row.count;
				const cars = row.count - row.sos_samecar;
				const orderTime = this.pipe.transform(row.mean_sos_ordertime);
				const transitTime = this.pipe.transform(row.mean_sos_linetime + row.mean_sos_paytime + row.mean_sos_hardservetime);
				const deliveryTime = this.pipe.transform(row.mean_sos_deliverytime);
				const totalTime = this.pipe.transform(this.totalTimeBKC(row));

				sheet.addRow([dayPart, ca, avgTicket, orders, cars, orderTime, transitTime, deliveryTime, totalTime]);
			});

			const totalRow = this.calculateTotalRowBKC(viewdata.dttReport);
			sheet.addRow(totalRow);
		} else {
			console.error('viewdata.dttReport is not an array:', viewdata.dttReport);
		}
	}

	private totalTimeBKC(row: api.SosDttReport): number {
		return (
			(row.mean_sos_ordertime || 0) +
			(row.mean_sos_linetime || 0) +
			(row.mean_sos_paytime || 0) +
			(row.mean_sos_hardservetime || 0) +
			(row.mean_sos_deliverytime || 0)
		);
	}

	private calculateTotalRowBKC(data: api.SosDttReport[]): any[] {
		const totalCA = this.total(data, 'total_revenue');
		const totalOrders = this.total(data, 'count');
		const totalCars = totalOrders - this.total(data, 'sos_samecar');

		const weightedAverage = (key: string) => {
			return data.reduce((sum, row) => sum + (row[key] || 0) * row.count, 0) / totalOrders;
		};

		const avgOrderTime = weightedAverage('mean_sos_ordertime');
		const avgTransitTime =
			weightedAverage('mean_sos_linetime') + weightedAverage('mean_sos_paytime') + weightedAverage('mean_sos_hardservetime');
		const avgDeliveryTime = weightedAverage('mean_sos_deliverytime');
		const avgTotalTime = data.reduce((sum, row) => sum + this.totalTimeBKC(row) * row.count, 0) / totalOrders;

		return [
			this.viewData.i18n['dtt_report']['day_part']['avrg_day'],
			this.currencyPipe.transform(totalCA, 'EUR'),
			this.currencyPipe.transform(totalCA / totalOrders, 'EUR'),
			totalOrders,
			totalCars,
			this.pipe.transform(avgOrderTime),
			this.pipe.transform(avgTransitTime),
			this.pipe.transform(avgDeliveryTime),
			this.pipe.transform(avgTotalTime),
		];
	}
}
