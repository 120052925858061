import { IAllergensInfo } from '@bk/jscommondatas';
import { createAction, props } from '@ngrx/store';

export const LoadAllergensInfo = createAction('[AllergenManagement] Loading allergens info');

export const LoadAllergensInfoSuccess = createAction(
	'[AllergenManagement] Loading allergens info success',
	props<{ data: IAllergensInfo }>()
);

export const UpdateAllergensInfo = createAction('[AllergenManagement] Update allergens info', props<{ data: IAllergensInfo }>());

export const UpdateAllergensInfoSuccess = createAction(
	'[AllergenManagement] Update allergens info success',
	props<{ data: IAllergensInfo }>()
);

export const RequestFailure = createAction('[AllergenManagement] Handle request error');
