import { createAction, props } from '@ngrx/store';
import { AppIngredientBaseInfo } from '@libs/gap/models';

export const LoadIngredientList = createAction('[IngredientManagement] Loading Ingredient list');

export const LoadIngredientListSuccess = createAction('[IngredientManagement] Loading Ingredient list success', props<{ data: AppIngredientBaseInfo[] }>());

export const LoadSpecificIngredient = createAction('[IngredientManagement] Loading specific Ingredient', props<{ id: number }>());

export const LoadSpecificIngredientSuccess = createAction(
	'[IngredientManagement] Loading specific Ingredient success',
	props<{
		id: number;
		data: AppIngredientBaseInfo;
	}>()
);


export const RequestFailure = createAction('[IngredientManagement] Handle request error');
