import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, effect, inject, Injector, signal, Signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Range } from '@libs/dash/core/entity';
import { HistoryLogsModule, SafeDepositReportsComponent } from '@libs/dash/features/v1';
import { ExportOption, ExportOptionType, LayoutFilterService } from '@libs/dash/features/v2';
import { ColumnType, CommonLayoutTableComponent, CommonSelectOption, CommonTableConfig } from '@libs/shared/modules/common-components';
import { TranslateModule, TranslateService } from '@libs/shared/modules/i18n';
import { map } from 'rxjs/operators';
import { SafeDepositFilterDataPipe } from './filter-data.pipe';

const SAFE_DEPOSIT_REPORTS_COLUMN = {
	Manager: 'Manager',
	ManagerId: 'ManagerId',
	Opening: 'businessDate',
	Closing: 'validationTime',
	Validation: 'sessionValidation',
	Reglements: 'reglements',
	Theoretical: 'theoretical',
	Declared: 'declared',
	Gap: 'gap',
	Comment: 'comment',
};

@Component({
	selector: 'safe-deposit-report-tab',
	standalone: true,
	imports: [CommonModule, TranslateModule, HistoryLogsModule, CommonLayoutTableComponent, SafeDepositFilterDataPipe],
	templateUrl: './safe-deposit-report-tab.component.html',
	styleUrl: './safe-deposit-report-tab.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SafeDepositReportTabComponent extends SafeDepositReportsComponent {
	private readonly injector: Injector = inject(Injector);
	private readonly translateService: TranslateService = inject(TranslateService);
	private readonly dr: DestroyRef = inject(DestroyRef);
	private readonly layoutFilterServiceService: LayoutFilterService = inject(LayoutFilterService);

	selectedVendors: Signal<string[]> = computed(() =>
		this.layoutFilterServiceService.filters?.safeDepositVendors ? this.layoutFilterServiceService.filters?.safeDepositVendors() : null
	);

	tableConfig: CommonTableConfig<any> = {
		titleKey: 'safe-deposit.title',
		detailsKey: 'entries',
		columns: [
			{
				key: SAFE_DEPOSIT_REPORTS_COLUMN.Manager,
				headerLabelKey: 'safe-deposit.reports.manager',
				alignment: 'left',
				columnType: ColumnType.Text,
				detailGetter: () => '',
				valueGetter: (_, row: any) => row.manager.login,
			},
			{
				key: SAFE_DEPOSIT_REPORTS_COLUMN.ManagerId,
				headerLabelKey: 'safe-deposit.reports.id',
				alignment: 'left',
				columnType: ColumnType.Text,
				detailGetter: () => '',
				valueGetter: (_, row: any) => row.manager.id,
			},
			{
				key: SAFE_DEPOSIT_REPORTS_COLUMN.Opening,
				headerLabelKey: 'safe-deposit.reports.opening',
				alignment: 'right',
				columnType: ColumnType.Date,
				info: { dateFormat: 'dd/MM/yyy - hh:mm' },
				detailGetter: () => '',
			},
			{
				key: SAFE_DEPOSIT_REPORTS_COLUMN.Closing,
				headerLabelKey: 'safe-deposit.reports.closing',
				alignment: 'right',
				columnType: ColumnType.Date,
				info: { dateFormat: 'dd/MM/yyy - hh:mm' },
			},
			{
				key: SAFE_DEPOSIT_REPORTS_COLUMN.Reglements,
				headerLabelKey: 'safe-deposit.reports.reglements',
				columnType: ColumnType.Text,
				alignment: 'right',
				totalColumnType: ColumnType.Translation,
				valueGetter: () => '',
				totalGetter: () => 'sessions-history.reports.total',
				detailGetter: (_, row: any) => row?.label,
			},
			{
				key: SAFE_DEPOSIT_REPORTS_COLUMN.Theoretical,
				headerLabelKey: 'safe-deposit.reports.amountTh',
				columnType: ColumnType.Price,
				alignment: 'right',
				valueGetter: (_, row: any) => this.totalCost(row, 1),
				totalGetter: (data: any[]) => data?.reduce((acc, row) => acc + this.totalCost(row, 1), 0),
				detailGetter: (_, row: any) => row.amount.theoretical,
			},
			{
				key: SAFE_DEPOSIT_REPORTS_COLUMN.Declared,
				headerLabelKey: 'safe-deposit.reports.declaredAmount',
				alignment: 'right',
				columnType: ColumnType.Price,
				valueGetter: (_, row: any) => this.totalCost(row, 2),
				totalGetter: (data: any[]) => data?.reduce((acc, row) => acc + this.totalCost(row, 2), 0),
				detailGetter: (_, row: any) => row.amount.real,
			},
			{
				key: SAFE_DEPOSIT_REPORTS_COLUMN.Gap,
				headerLabelKey: 'sessions-history.reports.gap',
				alignment: 'right',
				columnType: ColumnType.Price,
				valueGetter: (_, row: any) => this.totalCost(row, 3),
				totalGetter: (data: any[]) => data?.reduce((acc, row) => acc + this.totalCost(row, 3), 0),
				detailGetter: (_, row: any) => row.amount.theoretical - row.amount.real,
			},
			{
				key: SAFE_DEPOSIT_REPORTS_COLUMN.Comment,
				headerLabelKey: 'sessions-history.reports.comments',
				alignment: 'left',
				columnType: ColumnType.Text,
				valueGetter: () => '',
				totalGetter: () => '',
			},
		],
	};

	localeTextSig = toSignal<Record<string, any>>(
		this.translateService.selectTranslation('safe-deposit').pipe(map((i18n: Record<string, any>) => i18n?.reports)),
		{
			injector: this.injector,
		}
	);

	override ngOnInit(): void {
		super.ngOnInit();

		effect(
			() => {
				const range = this.layoutFilterServiceService?.range();
				const restaurant = this.layoutFilterServiceService?.filters?.restaurant();
				if (range && restaurant) {
					this.dateRange.setValue({ from: range.from.toJSDate(), to: range.to.toJSDate() });
					this.setPeriod(Range.Period);
				}
			},
			{ injector: this.injector, allowSignalWrites: true }
		);

		effect(
			() => {
				const texts = this.localeTextSig();
				if (texts) {
					this.localeText = texts;
				}
			},
			{ injector: this.injector, allowSignalWrites: true }
		);

		effect(
			() => {
				const data = this.dataSig();
				if (data) {
					let options = [];
					data.forEach((item: any) => {
						if (!options.some((o) => o.id === item.manager.id)) {
							options.push(item.manager);
						}
					});
					this.layoutFilterServiceService.registerFilterOptions(
						'safeDepositVendors',
						signal(
							options.map(
								(i: any) =>
									({
										value: i.id.toString(),
										label: i.login,
									}) as CommonSelectOption
							)
						)
					);
				}
			},
			{ injector: this.injector, allowSignalWrites: true }
		);

		const availableExportOptions: ExportOption[] = [
			{ label: ExportOptionType.PDF, selected: false, type: ExportOptionType.PDF },
			{ label: ExportOptionType.XLSX, selected: false, type: ExportOptionType.XLSX },
		];

		this.layoutFilterServiceService.setAvailableExportOptions(availableExportOptions);

		this.layoutFilterServiceService.export
			.pipe(takeUntilDestroyed(this.dr))
			.subscribe((selectedOptions: string[]) => this._exportData(selectedOptions));
	}

	private _exportData(selectedOptions: string[]) {
		if (selectedOptions.length === 0) {
			return;
		}
		for (const option of selectedOptions) {
			switch (option) {
				case ExportOptionType.CSV:
					this.downloadAll();
					break;
				case ExportOptionType.XLSX:
					this.exportXLSX();
					break;
			}
		}
	}
}
