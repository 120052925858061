import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IFeaturePaymentManagementState } from '../interfaces';

export const selectAppState = createFeatureSelector<IFeaturePaymentManagementState>('payment-management');

export const getPaymentMethodListData = createSelector(selectAppState, (state) => state.data);

export const getSpecificPaymentMethod = createSelector(selectAppState, (state, id: number) => {
	return state.data.find((paymentMethod) => paymentMethod.id === id);
});

export const isLoading = createSelector(selectAppState, (state) => state.loading);
