import { createReducer, on } from '@ngrx/store';
import { IFeatureMonitoringState } from '../interfaces';
import {
	LoadMonitoringRestaurantsInfo,
	LoadMonitoringRestaurantsInfoSuccess,
	LoadOverallStatus,
	LoadOverallStatusSuccess,
	LoadSpecificMonitoringRestaurantInfo,
	LoadSpecificMonitoringRestaurantInfoSuccess,
	RequestFailure,
} from './actions';

export const initialState: IFeatureMonitoringState = {
	data: {
		monitoringRestaurantsInfo: {},
		overallStatus: {},
	},
	loading: true,
};

export const reducer = createReducer(
	initialState,

	on(LoadOverallStatus, (state) => {
		return { ...state, loading: true };
	}),

	on(LoadOverallStatusSuccess, (state, action) => {
		return {
			...state,
			data: {
				monitoringRestaurantsInfo: { ...state.data.monitoringRestaurantsInfo },
				overallStatus: { ...action.data },
			},
			loading: false,
		};
	}),

	on(LoadMonitoringRestaurantsInfo, (state) => {
		return { ...state, loading: true };
	}),

	on(LoadMonitoringRestaurantsInfoSuccess, (state, action) => {
		return {
			...state,
			data: {
				monitoringRestaurantsInfo: { ...action.data },
				overallStatus: { ...state.data.overallStatus },
			},
			loading: false,
		};
	}),

	on(LoadSpecificMonitoringRestaurantInfo, (state) => {
		return { ...state, loading: true };
	}),

	on(LoadSpecificMonitoringRestaurantInfoSuccess, (state, action) => {
		return {
			...state,
			data: {
				monitoringRestaurantsInfo: {
					...state.data.monitoringRestaurantsInfo,
					restaurantsData: state.data.monitoringRestaurantsInfo.restaurantsData?.map((restaurantInfo) => {
						return action.data.id === restaurantInfo.id ? { ...action.data } : { ...restaurantInfo };
					}) || [{ ...action.data }],
				},
				overallStatus: { ...state.data.overallStatus },
			},
			loading: false,
		};
	}),

	on(RequestFailure, (state, action) => {
		return { ...state, loading: false };
	})
);
