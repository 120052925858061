import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, effect, inject, Injector, OnInit } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import * as api from '@dki/api-client';
import { EmployeeMeals } from '@dki/api-client';
import { Range } from '@libs/dash/core/entity';
import { EmployeeMealsComponent, ReportsModule } from '@libs/dash/features/v1';
import { ExportOption, ExportOptionType, LayoutFilterService } from '@libs/dash/features/v2';
import { ColumnType, CommonLayoutTableComponent, CommonTableConfig } from '@libs/shared/modules/common-components';
import { map } from 'rxjs/operators';

const EMPLOYEES_REPORTS_COLUMNS = {
	Restaurant: 'restaurant_id',
	Name: 'name',
	FirstName: 'first_name',
	Quantity: 'count',
	Total: 'total_value',
	TotalExcludingVat: 'total_value_excluding_vat',
};

@Component({
	selector: 'employees-reports-tab',
	standalone: true,
	imports: [CommonModule, CommonLayoutTableComponent, ReportsModule],
	templateUrl: './employees-reports-tab.component.html',
	styleUrl: './employees-reports-tab.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeesReportsTabComponent extends EmployeeMealsComponent implements OnInit {
	private readonly injector: Injector = inject(Injector);
	private readonly dr: DestroyRef = inject(DestroyRef);

	private readonly layoutFilterServiceService: LayoutFilterService = inject(LayoutFilterService);

	dataSig = toSignal<EmployeeMeals[]>(
		this.viewData$.pipe(map((data: { employeeMeals: api.EmployeeMealReport; i18n }) => data.employeeMeals.employee_meals)),
		{ injector: this.injector }
	);

	tableConfig: CommonTableConfig<EmployeeMeals> = {
		titleKey: 'reports.employee-meals',
		columns: [
			{
				key: EMPLOYEES_REPORTS_COLUMNS.Restaurant,
				headerLabelKey: 'employee-meals.restaurant',
				alignment: 'left',
				columnType: ColumnType.Text,
				valueGetter: (cell: string) => {
					return this.selectedRestaurantsObjects.find((r) => r.id === cell)?.name
				},
			},
			{
				key: EMPLOYEES_REPORTS_COLUMNS.Name,
				headerLabelKey: 'employee-meals.name',
				alignment: 'left',
				columnType: ColumnType.Text,
				valueGetter: (cell: unknown, row: EmployeeMeals) => row.employee.last_name,
			},
			{
				key: EMPLOYEES_REPORTS_COLUMNS.FirstName,
				headerLabelKey: 'employee-meals.firstname',
				alignment: 'left',
				columnType: ColumnType.Text,
				totalColumnType: ColumnType.Translation,
				valueGetter: (cell: unknown, row: EmployeeMeals) => row.employee.first_name,
				totalGetter: () => 'employee-meals.total',
			},
			{
				key: EMPLOYEES_REPORTS_COLUMNS.Quantity,
				headerLabelKey: 'employee-meals.quantity',
				alignment: 'right',
				columnType: ColumnType.Text,
				totalGetter: (data: EmployeeMeals[]) => data?.reduce((acc, i) => acc + i.count, 0),
			},
			{
				key: EMPLOYEES_REPORTS_COLUMNS.Total,
				headerLabelKey: 'employee-meals.totalttc',
				alignment: 'right',
				columnType: ColumnType.Price,
				totalGetter: (data: EmployeeMeals[]) => data?.reduce((acc, i) => acc + i.total_value, 0),
			},
		],
	};

	override ngOnInit(): void {
		super.ngOnInit();

		effect(
			() => {
				const range = this.layoutFilterServiceService?.range();
				const restaurant = this.layoutFilterServiceService?.filters.restaurant();

				if (range && restaurant) {
					this.dateRange.setValue({ from: range.from.toJSDate(), to: range.to.toJSDate() });
					this.setPeriod(Range.Period);
				}
			},
			{ injector: this.injector, allowSignalWrites: true }
		);
		const availableExportOptions: ExportOption[] = [
			{ label: ExportOptionType.PDF, selected: false, type: ExportOptionType.PDF },
			{ label: ExportOptionType.XLSX, selected: false, type: ExportOptionType.XLSX },
		];
		this.layoutFilterServiceService.setAvailableExportOptions(availableExportOptions);

		this.layoutFilterServiceService.export
			.pipe(takeUntilDestroyed(this.dr))
			.subscribe((selectedOptions: string[]) => this._exportData(selectedOptions));
	}

	private _exportData(type: string[]) {
		if (type.length === 0) {
			return;
		}
		for (const option of type) {
			switch (option) {
				case ExportOptionType.PDF:
					this.downloadPDF();
					break;
				case ExportOptionType.CSV:
					this.downloadCSV();
					break;
				case ExportOptionType.XLSX:
					this.downloadXlsx();
					break;
			}
		}
	}
}
