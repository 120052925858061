import { Pipe, PipeTransform } from '@angular/core';
import { CommonTableColumnSecondaryGroup } from '@libs/shared/modules/common-components';

@Pipe({
	name: 'productReportsTableGroupsSecondFilter',
	standalone: true,
})
export class ProductReportsTableGroupsSecondaryFilterPipe implements PipeTransform {
	transform(value: CommonTableColumnSecondaryGroup[], selectedChannels: string[]): CommonTableColumnSecondaryGroup[] {
		if (selectedChannels && selectedChannels.length > 0 && value && value.length > 0) {
			return value.filter(
				(group) => !group.columns.some((c) => c.includes('__')) || selectedChannels.includes(group.columns[0].split('__')[0])
			);
		}
		return value;
	}
}
