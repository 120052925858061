import { AppOptionInfo, AppOptionMediaOperation } from '@libs/shared/modules/frontend-common';
import { createAction, props } from '@ngrx/store';

export const LoadOptionsList = createAction('[OptionsManagement] Loading restaurant Options list');

export const LoadOptionsListSuccess = createAction('[OptionsManagement] Loading Options list success', props<{ data: AppOptionInfo[] }>());

export const LoadSpecificOption = createAction('[OptionsManagement] Loading specific Options', props<{ id: number }>());

export const LoadSpecificOptionSuccess = createAction(
	'[OptionsManagement] Loading specific Options success',
	props<{
		id: number;
		data: AppOptionInfo;
	}>()
);

export const CreateOption = createAction('[OptionsManagement] Create new Option', props<{ data: AppOptionInfo }>());

export const CreateOptionSuccess = createAction('[OptionsManagement] Create new Option success', props<{ data: AppOptionInfo }>());

export const UpdateOption = createAction(
	'[OptionsManagement] Updates specific Option',
	props<{ id: number; data: AppOptionInfo; mediaOperation: AppOptionMediaOperation }>()
);

export const UpdateOptionSuccess = createAction(
	'[OptionsManagement] Updates specific Option success',
	props<{ id: number; data: AppOptionInfo }>()
);

export const DeleteOption = createAction('[OptionsManagement] Delete specific Option', props<{ id: number }>());

export const DeleteOptionSuccess = createAction('[OptionsManagement] Delete specific Option success');

export const RequestFailure = createAction('[OptionsManagement] Handle request error');
