import { createReducer, on } from '@ngrx/store';
import { IFeatureIngredientManagementState } from '../interfaces';
import {
	LoadIngredientList,
	LoadIngredientListSuccess,
	LoadSpecificIngredient,
	LoadSpecificIngredientSuccess,
	RequestFailure
} from './actions';
import { lensPath, pipe, set } from 'ramda';


export const initialState: IFeatureIngredientManagementState = {
	data: [],
	loading: false,
};

export const reducer = createReducer(
	initialState,
	on(LoadIngredientList, (state) => {
		return <IFeatureIngredientManagementState>(
			set(lensPath(['loading']), true)(state)
		);
	}),
	on(LoadIngredientListSuccess, (state, action) => {
		return <IFeatureIngredientManagementState>(
			pipe(
				set(lensPath(['data']), action.data),
				set(lensPath(['loading']), false)
			)(state)
		);
	}),
	on(LoadSpecificIngredient, (state) => {
		return <IFeatureIngredientManagementState>(
			set(lensPath(['loading']), true)(state)
		);
	}),
	on(LoadSpecificIngredientSuccess, (state, action) => {
		const isCurrentPresent = state.data.some((item) => item.id === action.id);
		let nextData = state.data?.length
			? isCurrentPresent
				? state.data.map((ingredient) => (ingredient.id === action.id ? { ...action.data, fullyLoaded: true } : { ...ingredient }))
				: [{ ...action.data, fullyLoaded: true}, ...state.data]
			: [{ ...action.data, fullyLoaded: true }];

		return <IFeatureIngredientManagementState>(
			pipe(
				set(lensPath(['data']), nextData),
				set(lensPath(['loading']), false)
			)(state)
		);
	}),
	on(RequestFailure, (state, action) => {
		return <IFeatureIngredientManagementState>(
			set(lensPath(['loading']), false)(state)
		);
	})
);
