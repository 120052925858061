import { createReducer, on } from '@ngrx/store';
import { IFeatureOwnerManagementState } from '../interfaces';
import {
	CreateRestaurantOwner,
	CreateRestaurantOwnerSuccess,
	DeleteRestaurantOwner,
	DeleteRestaurantOwnerSuccess,
	LoadRestaurantOwnerList,
	LoadRestaurantOwnerListSuccess,
	LoadSpecificRestaurantOwner,
	LoadSpecificRestaurantOwnerSuccess,
	RequestFailure,
	UpdateRestaurantOwner,
	UpdateRestaurantOwnerSuccess,
} from './actions';

export const initialState: IFeatureOwnerManagementState = {
	data: [],
	loading: true,
};

export const reducer = createReducer(
	initialState,
	on(LoadRestaurantOwnerList, (state) => {
		return { ...state, loading: true };
	}),
	on(LoadRestaurantOwnerListSuccess, (state, action) => {
		return { ...state, data: action.data, loading: false };
	}),
	on(LoadSpecificRestaurantOwner, (state) => {
		return { ...state, loading: true };
	}),
	on(LoadSpecificRestaurantOwnerSuccess, (state, action) => {
		let nextData = state.data?.length
			? state.data.map((owner) => (owner.id === action.id ? { ...action.data } : { ...owner }))
			: [{ ...action.data }];
		return { ...state, data: nextData, loading: false };
	}),
	on(CreateRestaurantOwner, (state, action) => {
		return { ...state, loading: true };
	}),
	on(CreateRestaurantOwnerSuccess, (state, action) => {
		return { ...state, loading: false };
	}),
	on(UpdateRestaurantOwner, (state, action) => {
		return { ...state, loading: true };
	}),
	on(UpdateRestaurantOwnerSuccess, (state, action) => {
		return { ...state, loading: false };
	}),
	on(DeleteRestaurantOwner, (state, action) => {
		return { ...state, loading: true };
	}),
	on(DeleteRestaurantOwnerSuccess, (state, action) => {
		return { ...state, loading: false };
	}),
	on(RequestFailure, (state, action) => {
		return { ...state, loading: false };
	})
);
