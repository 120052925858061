import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IFeatureOwnerManagementState } from '../interfaces';

export const selectAppState = createFeatureSelector<IFeatureOwnerManagementState>('owner-management');

export const getRestaurantOwnerListData = createSelector(selectAppState, (state) => state.data);

export const getSpecificRestaurantOwner = createSelector(selectAppState, (state, id: number) =>
	state.data.find((owner) => owner.id === id)
);

export const isLoading = createSelector(selectAppState, (state) => state.loading);
