import { animate, state, style, transition, trigger } from '@angular/animations';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, inject, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTable, MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@libs/shared/modules/i18n';
import { AngularResizeEventModule } from 'angular-resize-event';
import { CommonLayoutTableDetailGetterPipe, CommonLayoutTableValueGetterPipe } from './common-layout-table-value-getter.pipe';

export enum ColumnType {
	Text = 'text',
	Translation = 'translation',
	Price = 'price',
	Percent = 'percent',
	Date = 'date',
	Custom = 'custom',
}

export type CommonTableColumnType = string;
export type ValueOf<T> = T[keyof T];

export interface CommonTableColumnGroup {
	titleKey: string;
	columns: CommonTableColumnType[];
	columnsMain?: CommonTableColumnType[];
	colspan?: number;
}

export interface CommonTableColumnSecondaryGroup {
	titleKey: string;
	columns: CommonTableColumnType[];
	colspan: number;
	key: string;
}

export interface CommonTableColumnConfig<T> {
	key: CommonTableColumnType;
	headerLabelKey: string;
	columnType: ColumnType;
	detailColumnType?: ColumnType;
	totalColumnType?: ColumnType;
	width?: string;
	valueGetter?: (cell?: ValueOf<T>, row?: T, rowIndex?: number, data?: T[]) => any;
	detailGetter?: (cell?: ValueOf<T>, row?: T, rowIndex?: number, data?: T[]) => any;
	totalGetter?: (data?: T[]) => any;
	info?: any;
	templateRef?: TemplateRef<any>;
	classGetter?: (cell?: ValueOf<T>, row?: T, rowIndex?: number, data?: T[]) => string;
	alignment?: string;
	colspan?: number;
	headerLabelPostfix?: string;
}

export interface CommonTableConfig<T> {
	titleKey: string;
	columns: CommonTableColumnConfig<T>[];
	detailsKey?: string;
	scrollable?: boolean;
}

@Component({
	selector: 'merim-common-layout-table',
	standalone: true,
	imports: [
		CommonModule,
		MatTableModule,
		MatIconModule,
		TranslateModule,
		CommonLayoutTableValueGetterPipe,
		CommonLayoutTableDetailGetterPipe,
		CdkTableModule,
		AngularResizeEventModule,
	],
	templateUrl: './common-layout-table.component.html',
	styleUrls: ['./common-layout-table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '0px', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	],
	providers: [CommonLayoutTableValueGetterPipe, CommonLayoutTableDetailGetterPipe, DatePipe],
})
export class CommonLayoutTableComponent<T> {
	private readonly _elementRef: ElementRef = inject(ElementRef);

	ColumnType = ColumnType;
	displayedColumns: CommonTableColumnConfig<T>[] = [];
	expandedElement: T | null = null;

	private _config: CommonTableConfig<T>;
	private _tableDisplayColumns: string[] = [];
	private _tableSecondaryGroupDisplayColumns: string[] = [];
	private _columnSecondaryGroups: CommonTableColumnSecondaryGroup[] = [];
	private _tableElement: Element;

	private _cachedHeaders: Map<string, Element> = new Map();
	private _cachedHeaderCells: Map<string, HTMLElement> = new Map();
	private _cachedCellWidths: Map<string, number> = new Map();

	get config(): CommonTableConfig<T> {
		return this._config;
	}

	get tableDisplayedColumns(): string[] {
		return this._tableDisplayColumns;
	}

	get tableSecondaryGroupDisplayedColumns(): string[] {
		return this._tableSecondaryGroupDisplayColumns;
	}

	get columnSecondaryGroups(): CommonTableColumnSecondaryGroup[] {
		return this._columnSecondaryGroups;
	}

	@Input() id: string;
	@Input() columnGroups: CommonTableColumnGroup[] = [];
	@Input() paddingTop: boolean = true;
	@Input({ required: true }) dataSource: T[] = [];

	@Input() set columnSecondaryGroups(columnGroups: CommonTableColumnSecondaryGroup[]) {
		if (columnGroups && columnGroups.length > 0) {
			this._columnSecondaryGroups = columnGroups;
			this._tableSecondaryGroupDisplayColumns = columnGroups.map((c) => c.key);
		}
	}

	@Input({ required: true }) set config(config: CommonTableConfig<T>) {
		this._handleConfigChange(config);
	}

	@ViewChild(MatTable) table: MatTable<T>;
	@ViewChild('matTable') matTable: ElementRef;

	contentChanged(): void {
		this.adjustColumnGroups();
		this._tableElement = this._elementRef.nativeElement;
	}

	isLastInHeaderGroup(columnKey: CommonTableColumnType): boolean {
		const foundGroup = this.columnSecondaryGroups.find((g) => g.columns.includes(columnKey));
		return foundGroup ? foundGroup.columns.indexOf(columnKey) === foundGroup.columns.length - 1 : false;
	}

	isLastInMainHeaderGroup(columnKey: CommonTableColumnType): boolean {
		const foundGroup = this.columnGroups.find((g) => g.columnsMain.includes(columnKey));
		return foundGroup ? foundGroup.columnsMain.indexOf(columnKey) === foundGroup.columnsMain.length - 1 : true;
	}

	isHeaderOnLastColumn(columnKey: CommonTableColumnType): boolean {
		return this.columnGroups
			.map((group) => group.columns)
			.concat(this.columnSecondaryGroups.map((group) => group.columns))
			.some((columns) => columns.some((c) => c === columnKey));
	}

	private _handleConfigChange(config: CommonTableConfig<T>): void {
		this._config = config;

		if (this._config) {
			this._tableDisplayColumns = this._config.columns.map((column) => column.key);
			this.displayedColumns = this.config.columns;
			this.adjustColumnGroups();
		}
	}

	toggleRow(element: T, event: MouseEvent): void {
		if (!this.config?.detailsKey) {
			return;
		}

		if (this._isClickOnIcon(event)) {
			event.stopPropagation();
		}
		this.expandedElement = this.expandedElement === element ? null : element;
		this.table.renderRows();
	}

	getHeaderColumnWidth(columnKey: CommonTableColumnType, element): number {
		if (element !== this.expandedElement) {
			return this._cachedCellWidths.get(columnKey) ?? 0;
		}

		const headerRow: Element = this._tableElement.getElementsByClassName('common-material-table-row')[0];
		const headerCell: HTMLElement = headerRow?.getElementsByClassName('mat-column-' + columnKey)[0] as HTMLElement;

		if (headerCell) {
			this._cachedCellWidths.set(columnKey, headerCell.offsetWidth);
		}

		return headerCell?.offsetWidth ?? 0;
	}

	private getHeaderCellElement(key: string, headerRow: Element): HTMLElement {
		let cell: HTMLElement = this._cachedHeaderCells.get(key);

		if (!cell && headerRow) {
			cell = headerRow?.getElementsByClassName(key)[0] as HTMLElement;

			if (cell) {
				this._cachedHeaderCells.set(key, cell);
			}
 		}

		return cell;
	}

	private getHeaderElement(key: string): Element {
		let header: Element = this._cachedHeaders.get(key);

		if (!header) {
			header = this._tableElement.getElementsByClassName(key)[0];

			if (header) {
				this._cachedHeaders.set(key, header);
			}
		}

		return header;
	}

	private _isClickOnIcon(event: MouseEvent): boolean {
		return (event.target as HTMLElement).tagName.toLowerCase() === 'mat-icon';
	}

	adjustColumnGroups(): void {
		setTimeout(() => {
			const headerRow = this._tableElement?.getElementsByClassName('mat-mdc-header-row')[0];
			if (!headerRow) {
				return;
			}

			const cells = headerRow.getElementsByClassName('mat-mdc-header-cell');
			const table = this._tableElement;
			const header = this._tableElement.getElementsByClassName('column-group-tab-primary')[0];
			const tabs = this._tableElement.getElementsByClassName('column-group-tab-secondary');
			const tableRect = table?.getBoundingClientRect();

			if (!tableRect || !cells || !header || this.config.columns.length === 0) {
				return;
			}

			this._adjustHeader(cells, header, tableRect);
			this.columnGroups.forEach((group, index) => {
				this._adjustColumnGroup(group, index, cells, tabs, tableRect);
			});
		})
	}

	private _adjustColumnGroup(
		group: CommonTableColumnGroup,
		index: number,
		cells: HTMLCollection,
		tabs: HTMLCollection,
		tableRect: DOMRect
	): void {
		const tab = tabs[index] as HTMLElement;
		const firstColumnIndex =
			this.tableSecondaryGroupDisplayedColumns.length > 0
				? this.tableSecondaryGroupDisplayedColumns.indexOf(group.columns[0])
				: this.tableDisplayedColumns.indexOf(group.columns[0]);
		const lastColumnIndex =
			this.tableSecondaryGroupDisplayedColumns.length > 0
				? this.tableSecondaryGroupDisplayedColumns.indexOf(group.columns[group.columns.length - 1])
				: this.tableDisplayedColumns.indexOf(group.columns[group.columns.length - 1]);

		if (firstColumnIndex !== -1 && lastColumnIndex !== -1 && tableRect) {
			this._setElementStyles(
				tab,
				firstColumnIndex,
				lastColumnIndex,
				cells,
				tableRect,
				(left) => left - 1,
				(width) => width + 1
			);
		}
	}

	private _adjustHeader(cells: HTMLCollection, header: Element, tableRect: DOMRect): void {
		const headerElement = header as HTMLElement;
		this._setElementStyles(headerElement, 0, 0, cells, tableRect);
	}

	private _setElementStyles(
		element: HTMLElement,
		firstColumnIndex: number,
		lastColumnIndex: number,
		cells: HTMLCollection,
		tableRect: DOMRect,
		leftGetter: (value: number) => number = (value: number) => value,
		widthGetter: (value: number) => number = (value: number) => value
	): void {
		const firstCell = cells[firstColumnIndex] as HTMLElement;
		const lastCell = cells[lastColumnIndex] as HTMLElement;
		if (firstCell && lastCell) {
			const firstCellRect = firstCell.getBoundingClientRect();
			const lastCellRect = lastCell.getBoundingClientRect();

			const left = firstCellRect.left - tableRect.left;
			const width = lastCellRect.right - firstCellRect.left;

			element.style.position = 'absolute';
			element.style.left = `${leftGetter(left)}px`;
			element.style.width = `${widthGetter(width)}px`;
		}
	}
}
