import { createReducer, on } from '@ngrx/store';
import { IFeaturePaymentManagementState } from '../interfaces';
import {
	CreatePaymentMethod,
	CreatePaymentMethodSuccess,
	DeletePaymentMethod,
	DeletePaymentMethodSuccess,
	LoadPaymentMethodList,
	LoadPaymentMethodListSuccess,
	LoadSpecificPaymentMethod,
	LoadSpecificPaymentMethodSuccess,
	RequestFailure,
	UpdatePaymentMethod,
	UpdatePaymentMethodSuccess,
} from './actions';

export const initialState: IFeaturePaymentManagementState = {
	data: [],
	loading: true,
};

export const reducer = createReducer(
	initialState,
	on(LoadPaymentMethodList, (state) => {
		return { ...state, loading: true };
	}),
	on(LoadPaymentMethodListSuccess, (state, action) => {
		return { ...state, data: action.data, loading: false };
	}),
	on(LoadSpecificPaymentMethod, (state) => {
		return { ...state, loading: true };
	}),
	on(LoadSpecificPaymentMethodSuccess, (state, action) => {
		let nextData = state.data?.length
			? state.data.map((paymentMethod) => (paymentMethod.id === action.id ? { ...action.data } : { ...paymentMethod }))
			: [{ ...action.data }];
		return { ...state, data: nextData, loading: false };
	}),
	on(CreatePaymentMethod, (state, action) => {
		return { ...state, loading: true };
	}),
	on(CreatePaymentMethodSuccess, (state, action) => {
		return { ...state, loading: false };
	}),
	on(UpdatePaymentMethod, (state, action) => {
		return { ...state, loading: true };
	}),
	on(UpdatePaymentMethodSuccess, (state, action) => {
		return { ...state, loading: false };
	}),
	on(DeletePaymentMethod, (state, action) => {
		return { ...state, loading: true };
	}),
	on(DeletePaymentMethodSuccess, (state, action) => {
		return { ...state, loading: false };
	}),
	on(RequestFailure, (state, action) => {
		return { ...state, loading: false };
	})
);
