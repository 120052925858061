import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, Signal } from '@angular/core';
import { DttExportModule, SourceType } from '@libs/dash/features/v1';
import { ExportOption, ExportOptionType, LayoutFilterService } from '../../../layout-filter-service/layout-filter.service';
import { BkfTableCumulComponent } from '../../partial-components/bkf-table-cumul/bkf-table-cumul.component';
import { BkfTableRestoComponent } from '../../partial-components/bkf-table-resto/bkf-table-resto.component';
import { BkfCumulWrapperComponent } from '../../partial-components/cumul-wrapper/bkf-cumul-wrapper.component';
import { BkfRestoWrapperComponent } from '../../partial-components/resto-wrapper/bkf-resto-wrapper.component';
import { DttExportGroupType } from '../../time-of-service.component';

export const BKF_TABLE_COLUMNS = {
	DayPart: 'day_part',
	Schedule: 'schedule',
	CA: 'total_revenue',
	AverageTicket: 'avg_tx',
	NumberOfTickets: 'number_tx',
	Cars: 'cars',
	Wait: 'mean_sos_waittime',
	OrderTime: 'mean_sos_ordertime',
	Order: 'order',
	LineTime: 'mean_sos_linetime',
	Payment: 'mean_sos_paytime',
	Transit: 'mean_sos_hardservetime',
	Delivery: 'mean_sos_deliverytime',
	Total: 'total',
	Parked: 'parked_cars',
	Depart: 'mean_sos_departtime',
};

@Component({
	selector: 'bkf',
	standalone: true,
	imports: [
		CommonModule,
		DttExportModule,
		BkfTableCumulComponent,
		BkfTableRestoComponent,
		BkfCumulWrapperComponent,
		BkfRestoWrapperComponent,
	],
	templateUrl: './bkf.component.html',
	styleUrl: './bkf.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BkfComponent {
	private readonly layoutFilterServiceService: LayoutFilterService = inject(LayoutFilterService);
	private readonly dr: DestroyRef = inject(DestroyRef);
	groupTypeSig: Signal<DttExportGroupType> = this.layoutFilterServiceService.filters.groupType;
	sourceTypeSig: Signal<SourceType> = this.layoutFilterServiceService.filters.source;

	groupTypes = DttExportGroupType;

	ngOnInit(): void {
		const availableExportOptions: ExportOption[] = [
			{ label: ExportOptionType.PDF, selected: false, type: ExportOptionType.PDF },
			{ label: ExportOptionType.XLSX, selected: false, type: ExportOptionType.XLSX },
		];
		this.layoutFilterServiceService.setAvailableExportOptions(availableExportOptions);
	}
}
