import { createReducer, on } from '@ngrx/store';
import { IFeatureProductManagementState } from '../interfaces';
import {
	LoadProductFamilyList,
	LoadProductFamilyListSuccess,
	LoadProductList,
	LoadProductListSuccess,
	LoadSpecificProduct,
	LoadSpecificProductFamily,
	LoadSpecificProductFamilySuccess,
	LoadSpecificProductSuccess,
	ProductFamilyRequestFailure,
	ProductRequestFailure,
} from './actions';
import { lensPath, pipe, set } from 'ramda';


export const initialState: IFeatureProductManagementState = {
	productData: [],
	productFamilyData: [],
	loading: {
		loadingProductData: false,
		loadingProductFamilyData: false,
	},
};

export const reducer = createReducer(
	initialState,
	on(LoadProductList, (state) => {
		return <IFeatureProductManagementState>(
			set(lensPath(['loading', 'loadingProductData']), true)(state)
		);
	}),
	on(LoadProductListSuccess, (state, action) => {
		return <IFeatureProductManagementState>(
			pipe(
				set(lensPath(['productData']), action.data),
				set(lensPath(['loading', 'loadingProductData']), false)
			)(state)
		);
	}),
	on(LoadSpecificProduct, (state) => {
		return <IFeatureProductManagementState>(
			set(lensPath(['loading']), true)(state)
		);
	}),
	on(LoadSpecificProductSuccess, (state, action) => {
		const isCurrentPresent = state.productData.some((item) => item.id === action.id);
		let nextData = state.productData?.length
			? isCurrentPresent
				? state.productData.map((product) => (product.id === action.id ? { ...action.data, fullyLoaded: true } : { ...product }))
				: [{ ...action.data, fullyLoaded: true}, ...state.productData]
			: [{ ...action.data, fullyLoaded: true }];

		return <IFeatureProductManagementState>(
			pipe(
				set(lensPath(['productData']), nextData),
				set(lensPath(['loading', 'loadingProductData']), false)
			)(state)
		);
	}),
	on(LoadProductFamilyList, (state) => {
		return <IFeatureProductManagementState>(
			set(lensPath(['loading', 'loadingProductFamilyData']), true)(state)
		);
	}),
	on(LoadProductFamilyListSuccess, (state, action) => {
		return <IFeatureProductManagementState>(
			pipe(
				set(lensPath(['productFamilyData']), action.data),
				set(lensPath(['loading', 'loadingProductFamilyData']), false)
			)(state)
		);
	}),
	on(LoadSpecificProductFamily, (state) => {
		return <IFeatureProductManagementState>(
			set(lensPath(['loading', 'loadingProductFamilyData']), true)(state)
		);
	}),
	on(LoadSpecificProductFamilySuccess, (state, action) => {
		const isCurrentPresent = state.productFamilyData.some((item) => item.id === action.id);
		let nextData = state.productFamilyData?.length
			? isCurrentPresent
				? state.productFamilyData.map((family) => (family.id === action.id ? { ...action.data, fullyLoaded: true } : { ...family }))
				: [{ ...action.data, fullyLoaded: true}, ...state.productFamilyData]
			: [{ ...action.data, fullyLoaded: true }];

		return <IFeatureProductManagementState>(
			pipe(
				set(lensPath(['productFamilyData']), nextData),
				set(lensPath(['loading', 'loadingProductFamilyData']), false)
			)(state)
		);
	}),
	on(ProductRequestFailure, (state, action) => {
		return <IFeatureProductManagementState>(
			set(lensPath(['loading', 'loadingProductData']), false)(state)
		);
	}),
	on(ProductFamilyRequestFailure, (state, action) => {
		return <IFeatureProductManagementState>(
			set(lensPath(['loading', 'loadingProductFamilyData']), false)(state)
		);
	})
);
