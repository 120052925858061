import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IFeatureTagManagementState } from '../interfaces';

export const selectAppState = createFeatureSelector<IFeatureTagManagementState>('tag-management');

export const getTagListData = createSelector(selectAppState, (state) => state.data);

export const getSpecificTag = createSelector(selectAppState, (state, id: number) => state.data.find((tag) => tag.id === id));

export const isLoading = createSelector(selectAppState, (state) => state.loading);
