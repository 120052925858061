import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, effect, inject, Injector, OnInit, Signal, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import * as api from '@dki/api-client';
import { OrdersByChannelWithShare } from '@dki/api-client';
import { Range } from '@libs/dash/core/entity';
import { OperatingReportTileComponent, ReportsModule } from '@libs/dash/features/v1';
import { ExportOption, ExportOptionType, LayoutFilterService } from '@libs/dash/features/v2';
import {
	OperatingReportsTabTableComponent,
	OPERATING_REPORTS_COLUMNS,
} from '../../partial-components/operating-reports-tab-table/operating-reports-tab-table.component';

export interface OperatingReportInternalData {
	operatingReport: api.OperatingReport;
	i18n: any;
}

export interface OperatingReportTableData extends OrdersByChannelWithShare {
	key: string;
}

@Component({
	selector: 'operating-reports-tab',
	standalone: true,
	imports: [CommonModule, ReportsModule, OperatingReportsTabTableComponent],
	templateUrl: './operating-reports-tab.component.html',
	styleUrl: './operating-reports-tab.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperatingReportsTabComponent extends OperatingReportTileComponent implements OnInit {
	private readonly injector: Injector = inject(Injector);
	private readonly dr: DestroyRef = inject(DestroyRef);

	private readonly _layoutFilterServiceService: LayoutFilterService = inject(LayoutFilterService);

	selectedChannels: Signal<string[]> = computed(() =>
		this._layoutFilterServiceService.filters?.channels ? this._layoutFilterServiceService.filters?.channels() : null
	);

	dataSig = toSignal<OperatingReportInternalData>(this.viewData$, { injector: this.injector });

	dataMappedSig = computed<OperatingReportTableData[]>(() => {
		if (!this.dataSig()) {
			return [];
		}

		this._layoutFilterServiceService.registerFilterOptions(
			'channels',
			signal([
				{ value: OPERATING_REPORTS_COLUMNS.POS, labelKey: 'operatingReportTile.channels.POS' },
				{ value: OPERATING_REPORTS_COLUMNS.Kiosk, labelKey: 'operatingReportTile.channels.KIOSK' },
				...Object.keys(this.dataSig()?.operatingReport?.total?.by_channel)
					.filter((key) => this.dataSig()?.operatingReport?.total?.by_channel[key].value && this.isVisible(key) && !this.hiddenChannel(key))
					.sort((a, b) => this.sortChannels({ key: a }, { key: b }))
					.map((key) => {
						return {
							value: key,
							labelKey: `operatingReportTile.channels.${key}`,
						};
					}),
			])
		);

		return Object.keys(this.dataSig().operatingReport.by_time)
			.filter((key: string) => this.dataSig().operatingReport.by_time[key]?.total?.value)
			.map((key: string) => {
				return {
					key,
					...this.dataSig().operatingReport.by_time[key],
				};
			});
	});

	override ngOnInit(): void {
		super.ngOnInit();
		this._layoutFilterServiceService.registerFilterOptions('channels', signal([]));

		effect(
			() => {
				const range = this._layoutFilterServiceService?.range();
				if (range) {
					this.dateRange.setValue({ from: range.from.toJSDate(), to: range.to.toJSDate() });
					this.setPeriod(Range.Period);
				} else {
					this.setPeriod(Range.Period);
				}
			},
			{ injector: this.injector, allowSignalWrites: true }
		);
		const availableExportOptions: ExportOption[] = [
			{ label: ExportOptionType.CSV, selected: false, type: ExportOptionType.CSV },
			{ label: ExportOptionType.PDF, selected: false, type: ExportOptionType.PDF },
			{ label: ExportOptionType.XLSX, selected: false, type: ExportOptionType.XLSX },
		];
		this._layoutFilterServiceService.setAvailableExportOptions(availableExportOptions);
		this._layoutFilterServiceService.export
			.pipe(takeUntilDestroyed(this.dr))
			.subscribe((selectedOptions: string[]) => this._exportData(selectedOptions));
	}

	private _exportData(selectedOptions: string[]) {
		if (selectedOptions.length === 0) {
			return;
		}
		for (const option of selectedOptions) {
			switch (option) {
				case ExportOptionType.PDF:
					this.downloadPDF();
					break;
				case ExportOptionType.CSV:
					this.downloadCsv();
					break;
				case ExportOptionType.XLSX:
					this.downloadXlsx();
					break;
			}
		}
	}
}
