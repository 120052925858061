import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IFeatureMonitoringState } from '../interfaces';

export const selectAppState = createFeatureSelector<IFeatureMonitoringState>('monitoring');

export const getOverallStatusData = createSelector(selectAppState, (state) => state.data.overallStatus);

export const getMonitoringRestaurantsList = createSelector(selectAppState, (state) => state.data.monitoringRestaurantsInfo);

export const getSpecificMonitoringRestaurantInfo = (id: string) =>
	createSelector(selectAppState, (state) => {
		return state.data.monitoringRestaurantsInfo?.restaurantsData?.find((restaurant) => restaurant.id === id);
	});

export const isLoading = createSelector(selectAppState, (state) => state.loading);
