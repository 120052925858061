import { Pipe, PipeTransform } from '@angular/core';
import { PERMISSIONS } from "@bk/frontend-common";
import { AuthServiceToken } from '../models';

@Pipe({
	name: 'permissions',
})
export class PermissionsPipe implements PipeTransform {
	constructor(private readonly authService: AuthServiceToken) {}

	transform(resource: string, minimalPermissionsRequired: PERMISSIONS): boolean {
		return this.authService.hasPermission(resource, minimalPermissionsRequired);
	}
}
