import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, effect, inject, Injector, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Range } from '@libs/dash/core/entity';
import { SynthesisReportComponent, SynthesisReportModule } from '@libs/dash/features/v1';
import { ExportOption, ExportOptionType, LayoutFilterService } from '@libs/dash/features/v2';
import { SynthesisReportsTabTableComponent } from '../../partial-components/synthesis-reports-tab-table/synthesis-reports-tab-table.component';

@Component({
	selector: 'synthesis-reports-tab',
	standalone: true,
	imports: [CommonModule, SynthesisReportsTabTableComponent, SynthesisReportModule],
	templateUrl: './synthesis-reports-tab.component.html',
	styleUrl: './synthesis-reports-tab.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SynthesisReportsTabComponent extends SynthesisReportComponent implements OnInit {
	private readonly injector: Injector = inject(Injector);
	private readonly dr: DestroyRef = inject(DestroyRef);
	private readonly layoutFilterServiceService: LayoutFilterService = inject(LayoutFilterService);

	override visible = true;

	override ngOnInit(): void {
		effect(
			() => {
				const range = this.layoutFilterServiceService?.range();
				const restaurant = this.layoutFilterServiceService?.filters?.restaurant();
				if (range && restaurant) {
					this.dateRange.setValue({ from: range.from.toJSDate(), to: range.to.toJSDate() });
					this.setPeriod(Range.Period);
				}
			},
			{ injector: this.injector, allowSignalWrites: true }
		);

		const availableExportOptions: ExportOption[] = [
			{ label: ExportOptionType.PDF, selected: false, type: ExportOptionType.PDF },
			{
				label: ExportOptionType.XLSX,
				selected: false,
				type: ExportOptionType.XLSX,
			},
		];
		this.layoutFilterServiceService.setAvailableExportOptions(availableExportOptions);

		this.layoutFilterServiceService.export
			.pipe(takeUntilDestroyed(this.dr))
			.subscribe((selectedOptions: string[]) => this._exportData(selectedOptions));
	}

	private _exportData(selectedOptions: string[]) {
		console.warn('Export is not implemented');
	}
}
