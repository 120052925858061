import { CommonModule, CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { getLocale } from '@libs/dash/core';
import { IPeriodData, Period, TimeStep, ValueType } from '@libs/dash/core/entity';
import { DataTableCumulComponent, SecondsToMins } from '@libs/dash/features/v1';
import { ExportOptionType, LayoutFilterService, SOS_TABLE_COLUMNS } from '@libs/dash/features/v2';
import { generateXlsx, ReportOptions } from '@libs/dash/shared';
import { ColumnType, CommonLayoutTableComponent, CommonTableColumnGroup, CommonTableConfig } from '@libs/shared/modules/common-components';
import { TranslateService } from '@libs/shared/modules/i18n';
import { DateTime } from 'luxon';

@Component({
	selector: 'sos-data-table-cumul',
	standalone: true,
	imports: [CommonModule, SecondsToMins, CommonLayoutTableComponent],
	templateUrl: './sos-data-table-cumul.component.html',
	styleUrl: './sos-data-table-cumul.component.scss',
	providers: [SecondsToMins],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SosDataTableCumulComponent extends DataTableCumulComponent implements OnInit {
	private readonly secondsToMins: SecondsToMins = inject(SecondsToMins);
	private readonly layoutFilterServiceService: LayoutFilterService = inject(LayoutFilterService);
	private readonly dr: DestroyRef = inject(DestroyRef);

	tableConfig: CommonTableConfig<IPeriodData>;
	currencyPipe = new CurrencyPipe(getLocale());

	tableGroups: CommonTableColumnGroup[] = [
		{
			titleKey: 'sosKioskTile.dataTable.operationalTimes',
			columns: [SOS_TABLE_COLUMNS.PreparationTime, SOS_TABLE_COLUMNS.ServeTime, SOS_TABLE_COLUMNS.TotalTime],
		},
	];

	@ViewChild('coloredTime', { read: TemplateRef, static: true }) coloredTime: TemplateRef<any>;

	override ngOnInit(): void {
		this.initConfig();
		this.layoutFilterServiceService.export
			.pipe(takeUntilDestroyed(this.dr))
			.subscribe((selectedOptions: string[]) => this._exportData(selectedOptions));
	}

	override getClassForValue(value: number, type: ValueType): string {
		if (!value || isNaN(value)) {
			return '';
		}
		return this.isThresholdExceeded(value, type) ? 'red' : 'green';
	}

	private initConfig(): void {
		this.tableConfig = {
			titleKey: 'sosKioskTile.dataTable.cumul',
			columns: [
				{
					key: SOS_TABLE_COLUMNS.Period,
					headerLabelKey: 'sosKioskTile.dataTable.slots',
					alignment: 'left',
					columnType: ColumnType.Translation,
					width: '19.5%',
					valueGetter: (cell: Period) => `sosKioskTile.daypartLabels.${cell}`,
				},
				{
					key: SOS_TABLE_COLUMNS.Schedule,
					headerLabelKey: 'sosKioskTile.dataTable.schedule',
					alignment: 'right',
					columnType: ColumnType.Text,
					width: '11.5%',
					valueGetter: (cell: Period, row: IPeriodData) => {
						const formatTime = (time: number) => time.toString().padStart(2, '0');
						const template = this.translateService.instant('sosKioskTile.timeRange');
						return template.replace('{start}', formatTime(row.start)).replace('{end}', formatTime(row.end));
					},
					totalGetter: () => this.translateService.instant('sosKioskTile.dataTable.totalAverage'),
				},
				{
					key: SOS_TABLE_COLUMNS.TotalRevenue,
					headerLabelKey: 'sosKioskTile.dataTable.revenue',
					alignment: 'right',
					columnType: ColumnType.Price,
					width: '11.5%',
					totalGetter: (data: IPeriodData[]) => this.getTotalForField('total_revenue'),
				},
				{
					key: SOS_TABLE_COLUMNS.MeanRevenue,
					headerLabelKey: 'sosKioskTile.dataTable.averageTicket',
					alignment: 'right',
					columnType: ColumnType.Price,
					width: '11.5%',
					totalGetter: (data: IPeriodData[]) => this.calculateAverageForField('total_revenue'),
				},
				{
					key: SOS_TABLE_COLUMNS.Count,
					headerLabelKey: 'sosKioskTile.dataTable.orderCount',
					alignment: 'right',
					columnType: ColumnType.Text,
					width: '11.5%',
					totalGetter: (data: IPeriodData[]) => this.getTotalForField('count'),
				},
				{
					key: SOS_TABLE_COLUMNS.PreparationTime,
					headerLabelKey: 'sosKioskTile.dataTable.preparation',
					alignment: 'right',
					columnType: ColumnType.Custom,
					width: '11.5%',
					info: { valueType: ValueType.Preparation },
					valueGetter: (cell: number) => cell,
					totalGetter: (data: IPeriodData[]) => this.secondsToMins.transform(this.calculateAverageForField(TimeStep.PreparationTime)),
					templateRef: this.coloredTime,
				},
				{
					key: SOS_TABLE_COLUMNS.ServeTime,
					headerLabelKey: 'sosKioskTile.dataTable.departure',
					alignment: 'right',
					columnType: ColumnType.Custom,
					width: '11.5%',
					info: { valueType: ValueType.Depart },
					valueGetter: (cell: number) => cell,
					totalGetter: (data: IPeriodData[]) => this.secondsToMins.transform(this.calculateAverageForField(TimeStep.ServeTime)),
					templateRef: this.coloredTime,
				},
				{
					key: SOS_TABLE_COLUMNS.TotalTime,
					headerLabelKey: 'sosKioskTile.dataTable.totalService',
					alignment: 'right',
					columnType: ColumnType.Custom,
					width: '11.5%',
					info: { valueType: ValueType.Total },
					valueGetter: (cell: number, row: IPeriodData) => row[TimeStep.PreparationTime] + row[TimeStep.ServeTime],
					templateRef: this.coloredTime,
					totalGetter: (data: IPeriodData[]) =>
						this.secondsToMins.transform(
							this.calculateAverageForField(TimeStep.ServeTime) + this.calculateAverageForField(TimeStep.PreparationTime)
						),
				},
			],
		};
	}

	override positionSecondLabel(): void {
		return;
	}

	private _exportData(type: string[]) {
		for (const option of type) {
			switch (option) {
				case ExportOptionType.XLSX:
					this.exportXLSX(this.data);
					break;
			}
		}
	}

	exportXLSX(data: any) {
		const reportOptions: ReportOptions = {
			setupHeaders: (sheet) => {
				const headers = this.tableConfig.columns.map((column) => this.translateService.instant(column.headerLabelKey));
				const headerRow = sheet.addRow(headers);
				headerRow.eachCell((cell) => {
					cell.font = { bold: true };
				});
				return headerRow;
			},
			prepareDataRows: (sheet) => {
				this.data.forEach((row: IPeriodData) => {
					const dataRow = this.tableConfig.columns.map((column) => {
						const value = column.valueGetter ? column.valueGetter(row[column.key], row) : row[column.key];
						switch (column.columnType) {
							case ColumnType.Translation:
								return this.translateService.instant(value);
							case ColumnType.Price:
								return this.currencyPipe.transform(value, 'EUR', 'symbol', '1.2-2');
							case ColumnType.Percent:
								return (value * 100).toFixed(2) + '%';
							case ColumnType.Custom:
								if (
									column.key === SOS_TABLE_COLUMNS.PreparationTime ||
									column.key === SOS_TABLE_COLUMNS.ServeTime ||
									column.key === SOS_TABLE_COLUMNS.TotalTime
								) {
									return this.secondsToMins.transform(value);
								}
								return value;
							default:
								return value;
						}
					});
					sheet.addRow(dataRow);
				});

				const totalRow = this.calculateTotalRow(this.data);
				sheet.addRow(totalRow);
				sheet.lastRow.eachCell((cell) => {
					cell.font = { bold: true };
				});
			},
			generateFileName: () => {
				const date = DateTime.now().toFormat('yyyy-MM-dd');
				return `SOS_Cumul_Report_${date}.xlsx`;
			},
		};

		const detailText = `Restaurant: ${this.layoutFilterServiceService.filters
			.restaurant()
			.map((r) => r.name)
			.join(
				', '
			)} \nPeriode: ${this.layoutFilterServiceService.range().from.toJSDate().toLocaleDateString()} - ${this.layoutFilterServiceService.range().to.toJSDate().toLocaleDateString()}`;

		generateXlsx(this.translateService.instant('sosKioskTile.dataTable.cumul'), detailText, reportOptions);
	}

	private calculateTotalRow(data: IPeriodData[]): any[] {
		const totalCount = data.reduce((sum, row) => sum + row.count, 0);
		const totalRevenue = data.reduce((sum, row) => sum + row.total_revenue, 0);

		const weightedAverage = (key: string) => {
			return data.reduce((sum, row) => sum + (row[key] || 0) * row.count, 0) / totalCount;
		};

		return this.tableConfig.columns.map((column) => {
			switch (column.key) {
				case SOS_TABLE_COLUMNS.Period:
					return this.translateService.instant('sosKioskTile.dataTable.totalAverage');
				case SOS_TABLE_COLUMNS.Schedule:
					return '';
				case SOS_TABLE_COLUMNS.TotalRevenue:
					return this.currencyPipe.transform(totalRevenue, 'EUR', 'symbol', '1.2-2');
				case SOS_TABLE_COLUMNS.MeanRevenue:
					return this.currencyPipe.transform(totalRevenue / totalCount, 'EUR', 'symbol', '1.2-2');
				case SOS_TABLE_COLUMNS.Count:
					return totalCount;
				case SOS_TABLE_COLUMNS.PreparationTime:
					return this.secondsToMins.transform(weightedAverage('mean_sos_preparationtime'));
				case SOS_TABLE_COLUMNS.ServeTime:
					return this.secondsToMins.transform(weightedAverage('mean_sos_servetime'));
				case SOS_TABLE_COLUMNS.TotalTime:
					return this.secondsToMins.transform(weightedAverage('mean_sos_preparationtime') + weightedAverage('mean_sos_servetime'));
				default:
					return '';
			}
		});
	}
}
