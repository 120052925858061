import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@libs/shared/modules/i18n';
import { uniqBy } from 'lodash-es';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { BREADCRUMB_ROUTE_DATA } from './constants';

import { BreadcrumbItem } from './models';
import { BreadcrumbService } from './services';

@Component({
	selector: 'merim-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
	@Input() hiddenBreadcrumbUrl: string[] = [];
	@Input() skippedBreadcrumb: string[] = [];

	private _router: Router = inject(Router);
	private _breadcrumbService: BreadcrumbService = inject(BreadcrumbService);
	private _activatedRoute: ActivatedRoute = inject(ActivatedRoute);
	private _translateService: TranslateService = inject(TranslateService);

	isLoaded = false;
	menuItems: BreadcrumbItem[] = [];

	@Output() breadcrumbs = new EventEmitter<BreadcrumbItem[]>();

	private readonly ngUnsubscribe$ = new Subject<void>();

	constructor() {}

	ngOnInit(): void {
		// we have to run this function at least once, because we are not subscribed to page routing when app inits
		// this is for page refresh
		this.updateBreadcrumbs();

		this._router.events
			.pipe(
				takeUntil(this.ngUnsubscribe$),
				filter((event) => event instanceof NavigationEnd)
			)
			.subscribe(() => this.updateBreadcrumbs());
	}

	getName(id: string | number): Observable<string> {
		return this._breadcrumbService.keyName$.pipe(
			takeUntil(this.ngUnsubscribe$),
			map((r) => {
				return r[id] || this._translateService.instant(id.toString());
			})
		);
	}

	updateBreadcrumbs() {
		this.menuItems = [];

		this.menuItems = this.hiddenBreadcrumbUrl.includes(this._router.url)
			? [] // Hide the breadcrumbs component
			: this.createBreadcrumbs(this._activatedRoute.root, '', []).filter((crumb) => !this.skippedBreadcrumb.includes(crumb.label));
		this.menuItems = uniqBy(this.menuItems, 'url');
		this.breadcrumbs.emit(this.menuItems);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe$.next();
		this.ngUnsubscribe$.complete();
	}

	private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: BreadcrumbItem[]) {
		let _url = url;
		const children: ActivatedRoute[] = route.children;

		if (children.length === 0) {
			return breadcrumbs;
		}

		for (const child of children) {
			const routeURL: string = child.snapshot.url.map((segment) => segment.path).join('/');

			if (routeURL !== '') {
				_url += `/${routeURL}`;
			}

			/**
			 * How it work for the label
			 * 1. if the crumb name was setup in routing - it will be used by default
			 * (includes a empty string)
			 * 2. using router id (example: users/12)
			 * 3. using params (the name of params should setup in the routing)
			 */
			const label = this.getCrumbName(child, breadcrumbs) ?? (routeURL || this.getParamValue(child));

			if (label) {
				breadcrumbs.push({
					label,
					url: _url,
				});
			}

			return this.createBreadcrumbs(child, _url, breadcrumbs);
		}
	}

	private getCrumbName(child: ActivatedRoute, breadcrumbs: BreadcrumbItem[]): string {
		let newBreadcrumb = child.snapshot.data[BREADCRUMB_ROUTE_DATA.BREADCRUMB];
		const isCrumbAlreadyPresent = breadcrumbs.some((breadcrumb) => breadcrumb.label === newBreadcrumb);
		if (isCrumbAlreadyPresent) {
			return undefined;
		} else {
			return newBreadcrumb;
		}
	}

	private getParamValue(child: ActivatedRoute): string {
		const param = child.snapshot.data[BREADCRUMB_ROUTE_DATA.PARAM];

		return child.snapshot.paramMap.get(param);
	}
}
