import { effect, Injectable, Injector, Signal, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map } from 'rxjs/operators';

const NO_LAYOUT_PADDING_ROUTES = ['media-editor'];

@UntilDestroy()
@Injectable({
	providedIn: 'root',
})
export class NoLayoutPaddingByRouterService {
	private _noLayoutPadding: WritableSignal<boolean> = signal(false);
	private _url: Signal<string>;

	constructor(
		private readonly router: Router,
		private readonly injector: Injector
	) {
		this.initUrlSignal();
		this.initUrlSignalEffect();
	}

	get noLayoutPadding(): WritableSignal<boolean> {
		return this._noLayoutPadding;
	}

	private initUrlSignal() {
		this._url = toSignal(
			this.router.events.pipe(
				untilDestroyed(this),
				filter((event) => event instanceof NavigationEnd),
				map((event: NavigationEnd) => event.urlAfterRedirects)
			),
			{ injector: this.injector }
		);
	}

	private initUrlSignalEffect() {
		effect(
			() => {
				this.checkLayoutPaddingByModule(this._url() ?? this.router.url);
			},
			{ injector: this.injector, allowSignalWrites: true }
		);
	}

	private checkLayoutPaddingByModule(url: string) {
		const urlSegments: string[] = url.split('/');
		this.noLayoutPadding.set(urlSegments.some((urlSegment) => NO_LAYOUT_PADDING_ROUTES.includes(urlSegment)));
	}
}
