import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { SnackbarService } from './snack-bar-service';
import { SnackbarSubjectType } from './snack-bar-subject-type';
import { SnackBarDesign, SnackBarType, SnackBarValues } from "./snack-bar-type";

// inspired by https://medium.com/@tejozarkar/angular-custom-snackbar-using-service-ff24e20eda7f
@UntilDestroy()
@Component({
	selector: 'merim-snack-bar',
	templateUrl: './snack-bar.component.html',
	styleUrls: ['./snack-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('state', [
			transition(':enter', [
				style({ top: '0px', transform: 'translate(-50%, 0%) scale(0.3)' }),
				animate(
					'150ms cubic-bezier(0, 0, 0.2, 1)',
					style({
						transform: 'translate(-50%, 0%) scale(1)',
						opacity: 1,
						top: '40px',
					})
				),
			]),
			transition(':leave', [
				animate(
					'150ms cubic-bezier(0.4, 0.0, 1, 1)',
					style({
						transform: 'translate(-50%, 0%) scale(0.3)',
						opacity: 0,
						top: '0px',
					})
				),
			]),
		]),
	],
})
export class SnackBarComponent implements OnInit {
	private readonly snackbarService: SnackbarService = inject(SnackbarService);
	private readonly cd: ChangeDetectorRef = inject(ChangeDetectorRef);

	@Input() class = '';
	@Input() animationDisabled = false;
	@Input() stackSnackbars = false;

	snackbars: SnackbarSubjectType[] = [];

	private defaultDismissButtonText = '✖';

	ngOnInit() {
		this.snackbarService
			.getObservable()
			.pipe(untilDestroyed(this))
			.subscribe((newSnackbar: SnackbarSubjectType) => {
				if (!newSnackbar) {
					this.snackbars = [];
					return;
				}

				if (newSnackbar.show === true) {
					const snackbar: SnackbarSubjectType = {
						...newSnackbar,
						type: newSnackbar.type ?? 'success',
						dismissButtonText: newSnackbar.dismissButtonText ?? this.defaultDismissButtonText,
					};

					if (this.stackSnackbars) {
						this.snackbars.push(snackbar);
					} else {
						this.snackbars = [snackbar];
					}

					this.cd.detectChanges();
				} else if (newSnackbar.show === false) {
					setTimeout(() => {
						// We need to handle usage, when user clicks on "cross" button to dismiss it
						// no need to call callback twice ....
						if (newSnackbar.onHideFunction) {
							newSnackbar.onHideFunction();
						}

						const snackbarIndexToDelete: number = this.snackbars.indexOf(newSnackbar);
						this.snackbars.splice(snackbarIndexToDelete, 1);

						this.cd.detectChanges();
					}, newSnackbar.timeout || 0);
				}
			});
	}

	dismiss(id: string): void {
		this.snackbarService.hide(id);
	}

	getSnackbarTailwindClasses(snackType: SnackBarType) {
		switch(snackType) {
			case SnackBarValues.success:
				return 'bg-success-40 text-neutral-95';
			case SnackBarValues.error:
				return 'bg-error-50 text-neutral-95';
			case SnackBarValues.info:
				return 'bg-neutralVariant-30 text-neutral-95';
			case SnackBarValues.warning:
				return 'bg-warning-50 text-neutral-95';
			default:
				return '';
		}
	}

	protected readonly SnackBarDesign = SnackBarDesign;
}
