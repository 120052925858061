import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	effect,
	inject,
	Injector,
	Input,
	OnInit,
	signal,
	Signal,
	WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { setPreferredLanguage } from '@libs/dash/core';
import { ApplicationModuleSections, LanguageISOCode } from '@libs/dash/core/entity';
import { LayoutFilterService } from '@libs/dash/features/v2';
import { FeatureMapService } from '@libs/dash/guards';
import { AuthStoreSelectors, AuthStoreState, RootStoreState, RouterStoreSelectors } from '@libs/dash/store';
import { MenuModel } from '@libs/shared/models';
import { LanguageCode, LanguageService, TranslateService } from '@libs/shared/modules/i18n';
import { ConfigurationService } from '@merim/utils';
import { select, Store } from '@ngrx/store';
import { NEW_UI_ROUTE } from 'libs/shared/services';
import { DateTime } from 'luxon';
import { combineLatest, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ManifestReaderService } from '../../shared/manifest-app-version/services';

@Component({
	selector: 'dash-app-content',
	templateUrl: './dash-app-content.component.html',
	styleUrl: './dash-app-content.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashAppContentComponent implements OnInit {
	private readonly manifestReaderService: ManifestReaderService = inject(ManifestReaderService);
	private readonly router: Router = inject(Router);
	private readonly translateService: TranslateService = inject(TranslateService);
	private readonly languageService: LanguageService = inject(LanguageService);
	private readonly configurationService: ConfigurationService = inject(ConfigurationService);
	private readonly fm: FeatureMapService = inject(FeatureMapService);
	private readonly dr: DestroyRef = inject(DestroyRef);
	private readonly injector: Injector = inject(Injector);
	private readonly store: Store<RootStoreState> = inject(Store<RootStoreState>);
	private readonly layoutFilterServiceService: LayoutFilterService = inject(LayoutFilterService);
	private readonly defaultManifestLocation = '/manifest.json';
	private reportsFeatureEnabled = false;
	private featureMap = this.fm.featureMap;
	private range = { from: DateTime.now().startOf('week'), to: DateTime.now().endOf('week') };

	availableLanguages: LanguageCode[];

	i18nSig = toSignal<{ language: string; texts: { [key: string]: string } }>(
		combineLatest([
			this.store.pipe(select(RouterStoreSelectors.getCurrentLanguageParam)),
			this.translateService.selectTranslation('navigation-bar'),
		]).pipe(
			map(([language, texts]) => {
				return {
					language,
					texts,
				};
			})
		)
	);

	hasAccountingPermissionSig = toSignal<boolean>(
		this.store.pipe(
			select(AuthStoreSelectors.getCurrentUser),
			takeUntilDestroyed(this.dr),
			map((user: AuthStoreState.CurrentUser) => {
				const accountingLevel = user?.permissions.filter(
					(permission) => !!permission.name && permission.name.toLowerCase() === 'accounting'
				)[0]?.level;
				const isAvailable = !!accountingLevel && accountingLevel.toLowerCase() !== 'none';
				return isAvailable || user?.is_admin;
			})
		)
	);

	@Input() manifestLocation: string = this.defaultManifestLocation;

	currentAppVersionSig: Signal<string> = toSignal(
		this.manifestReaderService.getAppVersion(this.manifestLocation).pipe(catchError(() => of('N/A')))
	);
	menuItemsSig: WritableSignal<MenuModel<ApplicationModuleSections>[]> = signal([]);
	userSig: Signal<AuthStoreState.CurrentUser> = toSignal(this.store.pipe(select(AuthStoreSelectors.getCurrentUser)));

	ngOnInit() {
		this.loadInitData();
	}

	onLogout(): void {
		this.router.navigate(['/' + ApplicationModuleSections.ACCOUNT + '/logout']);
	}

	private loadInitData(): void {
		this.reportsFeatureEnabled = this.configurationService.hasFeature('reports');
		this.availableLanguages = this.languageService.allAvailableLanguages;
		this.layoutFilterServiceService.setRange(this.range);

		effect(
			() => {
				if (!!this.i18nSig() && !!this.hasAccountingPermissionSig()) {
					this.createPageNavigation(this.i18nSig().texts, this.hasAccountingPermissionSig());
				}
			},
			{ injector: this.injector, allowSignalWrites: true }
		);
	}

	private createPageNavigation(translates: { [key: string]: string }, accountingPermissions: boolean): void {
		const navigationBarItems: MenuModel<ApplicationModuleSections>[] = [];

		const generateSettings = (navigationBarItems: MenuModel<ApplicationModuleSections>[]): void => {
			navigationBarItems.push({
				text: 'navigation-bar.settings',
				url: `/${NEW_UI_ROUTE}/${ApplicationModuleSections.SETTINGS}`,
				icon: 'settings',
				submenu: [],
			});
		};

		const generateGlobalOverview = (navigationBarItems: MenuModel<ApplicationModuleSections>[]): void => {
			if (this.featureMap.sidebar.globalOverview) {
				navigationBarItems.push({
					text: 'navigation-bar.global-overview',
					url: `/${NEW_UI_ROUTE}/${ApplicationModuleSections.GLOBAL_OVERVIEW}`,
					icon: 'pie_chart',
					submenu: [],
				});
			}
		};

		const generateReports = (navigationBarItems: MenuModel<ApplicationModuleSections>[]): void => {
			if (this.featureMap.sidebar.sales && this.reportsFeatureEnabled) {
				navigationBarItems.push({
					text: 'navigation-bar.reports',
					url: `/${NEW_UI_ROUTE}/${ApplicationModuleSections.SALES_REPORTS}`,
					icon: 'euro',
					submenu: [],
				});
			}
		};

		const generateHistory = (navigationBarItems: MenuModel<ApplicationModuleSections>[]): void => {
			if (this.featureMap.sidebar.history) {
				navigationBarItems.push({
					text: 'navigation-bar.history-logs',
					url: `/${NEW_UI_ROUTE}/${ApplicationModuleSections.HISTORY_LOGS}`,
					icon: 'account_balance',
					submenu: [],
				});
			}
		};

		const generateDtt = (navigationBarItems: MenuModel<ApplicationModuleSections>[]): void => {
			if (this.featureMap.sidebar.dtt && this.reportsFeatureEnabled) {
				navigationBarItems.push({
					text: 'navigation-bar.reports',
					url: `/${NEW_UI_ROUTE}/${ApplicationModuleSections.DTT_EXPORT}`,
					icon: 'garage',
					submenu: [],
				});
			}
		};

		const generateSos = (navigationBarItems: MenuModel<ApplicationModuleSections>[]): void => {
			if (this.featureMap.sidebar.sos && this.reportsFeatureEnabled) {
				navigationBarItems.push({
					text: 'navigation-bar.speed-of-service',
					url: `/${NEW_UI_ROUTE}/${ApplicationModuleSections.TOS}`,
					icon: 'alarm',
					submenu: [],
				});
			}
		};

		const generateAccounting = (navigationBarItems: MenuModel<ApplicationModuleSections>[]): void => {
			if (this.featureMap.sidebar.accounting && accountingPermissions) {
				navigationBarItems.push({
					text: 'navigation-bar.accounting',
					url: `/${NEW_UI_ROUTE}/${ApplicationModuleSections.ACCOUNTING}`,
					icon: 'receipt',
					submenu: [],
				});
			}
		};

		const generatePerformance = (navigationBarItems: MenuModel<ApplicationModuleSections>[]): void => {
			if (this.featureMap.sidebar.performance) {
				navigationBarItems.push({
					text: 'navigation-bar.performance',
					url: `/${NEW_UI_ROUTE}/${ApplicationModuleSections.PERFORMANCE}`,
					icon: 'readiness_score',
					submenu: [],
				});
			}
		};

		//generateGlobalOverview(navigationBarItems);
		generateHistory(navigationBarItems);
		generateReports(navigationBarItems);
		//generateDtt(navigationBarItems);
		generateSos(navigationBarItems);
		//generateAccounting(navigationBarItems);
		//generatePerformance(navigationBarItems);
		//generateSettings(navigationBarItems);
		this.menuItemsSig.update(() => navigationBarItems);
	}
}
