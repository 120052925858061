import { AppRestaurantOwnerInfo } from '@libs/gap/models';
import { createAction, props } from '@ngrx/store';

export const LoadRestaurantOwnerList = createAction('[OwnerManagement] Loading restaurant owner list');

export const LoadRestaurantOwnerListSuccess = createAction(
	'[OwnerOwner] Loading restaurant owner list success',
	props<{
		data: AppRestaurantOwnerInfo[];
	}>()
);

export const LoadSpecificRestaurantOwner = createAction('[OwnerManagement] Loading specific restaurant owner', props<{ id: number }>());

export const LoadSpecificRestaurantOwnerSuccess = createAction(
	'[OwnerManagement] Loading specific restaurant owner success',
	props<{
		id: number;
		data: AppRestaurantOwnerInfo;
	}>()
);

export const CreateRestaurantOwner = createAction(
	'[OwnerManagement] Create new restaurant owner',
	props<{
		data: AppRestaurantOwnerInfo;
	}>()
);

export const CreateRestaurantOwnerSuccess = createAction(
	'[OwnerManagement] Create new restaurant owner success',
	props<{
		data: AppRestaurantOwnerInfo;
	}>()
);

export const UpdateRestaurantOwner = createAction(
	'[OwnerManagement] Updates specific restaurant owner',
	props<{
		id: number;
		data: AppRestaurantOwnerInfo;
	}>()
);

export const UpdateRestaurantOwnerSuccess = createAction(
	'[OwnerManagement] Updates specific restaurant owner success',
	props<{
		id: number;
		data: AppRestaurantOwnerInfo;
	}>()
);

export const DeleteRestaurantOwner = createAction('[OwnerManagement] Delete specific restaurant owner', props<{ ids: number[] }>());

export const DeleteRestaurantOwnerSuccess = createAction('[OwnerManagement] Delete specific restaurant owner success');

export const RequestFailure = createAction('[OwnerManagement] Handle request error');
