@if (config) {
	<div class="relative overflow-x-auto pt-16" [id]="id" [style.padding-top]="!paddingTop ? '0px' : '60px'">
		<div class="relative w-full my-10">
			<div class="absolute top-[-40px] left-0 right-0 h-10 flex">
				<div
					class="column-group-tab column-group-tab-primary bg-baseLayer-secondary text-primary-100 z-0 absolute font-medium h-full px-3 py-2 rounded-tl-lg rounded-tr-lg whitespace-nowrap overflow-hidden text-ellipsis flex items-center justify-start box-border"
				>
					<span class="overflow-hidden whitespace-nowrap text-ellipsis">{{ config.titleKey | translate }}</span>
				</div>
				@for (group of columnGroups; track group.titleKey) {
					<div
						class="column-group-tab column-group-tab-secondary bg-transparent text-baseLayer-secondary border border-b-0 border-solid border-neutral-50 z-0 font-medium absolute h-full px-3 py-2 rounded-tl-lg rounded-tr-lg whitespace-nowrap overflow-hidden text-ellipsis flex items-center justify-start box-border"
					>
						<span class="overflow-hidden whitespace-nowrap text-ellipsis">{{ group.titleKey | translate }}</span>
					</div>
				}
			</div>
			<table
				#matTable
				mat-table
				(resized)="adjustColumnGroups()"
				id="common-material-table"
				class="w-auto max-h-[400px] mt-10 border-separate border-spacing-0 !table-fixed relative"
				[class]="config?.scrollable === true ? 'w-auto' : 'w-100'"
				(contentChanged)="contentChanged()"
				[dataSource]="dataSource"
				multiTemplateDataRows="true"
			>

				@if (columnSecondaryGroups.length > 0) {
					@for (column of columnSecondaryGroups; track column.key; let columnIndex = $index; let last = $last) {
						<ng-container [matColumnDef]="column.key">
							<th mat-header-cell *matHeaderCellDef
							    [class.rounded-tr-lg]="last && !isHeaderOnLastColumn(column.key)"
							    [attr.colspan]="column.colspan">
								{{ column.titleKey | translate }}
							</th>
						</ng-container>
					}
				}

				@for (column of displayedColumns; track column.key; let columnIndex = $index; let last = $last) {
					<ng-container [matColumnDef]="column.key">
						<th mat-header-cell *matHeaderCellDef
						    [class.border-hidden]="tableSecondaryGroupDisplayedColumns.length > 0 && !isLastInHeaderGroup(column.key)"
						    [class.border-accent]="tableSecondaryGroupDisplayedColumns.length > 0 && isLastInMainHeaderGroup(column.key)"
						    [class.secondary-header-provided]="tableSecondaryGroupDisplayedColumns.length > 0"
						    [class.rounded-tr-lg]="last && !isHeaderOnLastColumn(column.key)"
						    [style.min-width]="column?.width ? column.width : 'calc(100% / ' + displayedColumns.length + ')'"
						    [style.width]="column?.width ? column.width : 'calc(100% / ' + displayedColumns.length + ')'"
						    [class]="(column?.classGetter && tableSecondaryGroupDisplayedColumns.length > 0) ? column.classGetter() : ''">
								{{ column.headerLabelKey | translate }}
							@if (column?.headerLabelPostfix) {
								<span> {{ column?.headerLabelPostfix }}</span>
							}
						</th>
						<td
							mat-cell
							*matCellDef="let element; let dataIndex = dataIndex"
							class="p-2"
							[class.odd]="dataIndex === 0 || dataIndex % 2 === 0"
							[style.min-width]="column?.width ? column.width : 'calc(100% / ' + displayedColumns.length + ')'"
							[style.width]="column?.width ? column.width : 'calc(100% / ' + displayedColumns.length + ')'"
							[class]="
								column?.classGetter
									? column.classGetter(column | CommonLayoutTableValueGetter: element : dataIndex, element, dataIndex)
									: ''
							"
						>
							<div
								class="flex w-full h-full overflow-hidden text-overflow-ellipsis whitespace-nowrap"
								[ngClass]="{
									'justify-end': column?.alignment === 'right',
									'justify-start': !column?.alignment || column?.alignment === 'left'
								}"
								[class.font-medium]="expandedElement === element"
								[class.justify-between]="config?.detailsKey && columnIndex === 0"
							>
								<div class="block text-ellipsis overflow-hidden">
									@if (columnIndex === 0 && config?.detailsKey) {
										<mat-icon class="!h-[14px] min-w-6 text-lg leading-none text-left">
											{{ expandedElement === element ? 'expand_more' : 'chevron_right' }}
										</mat-icon>
									}

									@switch (column.columnType) {
										@case (ColumnType.Translation) {
											{{ column | CommonLayoutTableValueGetter: element : dataIndex : dataSource | translate }}
										}
										@case (ColumnType.Price) {
											{{ column | CommonLayoutTableValueGetter: element : dataIndex : dataSource | currency: 'EUR' : 'symbol' : '1.2-2' }}
										}
										@case (ColumnType.Percent) {
											{{ column | CommonLayoutTableValueGetter: element : dataIndex : dataSource | percent: '1.2-2' }}
										}
										@case (ColumnType.Text) {
											{{ column | CommonLayoutTableValueGetter: element : dataIndex : dataSource }}
										}
										@case (ColumnType.Date) {
											@if (column?.info?.dateFormat) {
												{{ column | CommonLayoutTableValueGetter: element : dataIndex : dataSource | date: column?.info?.dateFormat }}
											} @else {
												{{ column | CommonLayoutTableValueGetter: element : dataIndex : dataSource | date }}
											}
										}
										@case (ColumnType.Custom) {
											<ng-container
												*ngTemplateOutlet="
													column?.templateRef;
													context: { data: column | CommonLayoutTableValueGetter: element : dataIndex, info: column.info, row: element }
												"
											></ng-container>
										}
									}
								</div>
							</div>
						</td>
						<td
							mat-footer-cell
							[style.min-width]="column?.width ? column.width : 'calc(100% / ' + displayedColumns.length + ')'"
							[style.width]="column?.width ? column.width : 'calc(100% / ' + displayedColumns.length + ')'"
							*matFooterCellDef="let element"
							class="custom-footer"
						>
							@switch (column.totalColumnType ?? column.columnType) {
								@case (ColumnType.Translation) {
									{{ column?.totalGetter ? (column.totalGetter(dataSource) | translate) : '' }}
								}
								@case (ColumnType.Price) {
									{{ column?.totalGetter ? (column.totalGetter(dataSource) | currency: 'EUR' : 'symbol' : '1.2-2') : '' }}
								}
								@case (ColumnType.Percent) {
									{{ column?.totalGetter ? (column.totalGetter(dataSource) | percent: '1.2-2') : '' }}
								}
								@default {
									{{ column?.totalGetter ? column.totalGetter(dataSource) : '' }}
								}
							}
						</td>
					</ng-container>
				}

				<ng-container matColumnDef="expandedDetail">
					<td mat-cell *matCellDef="let element" [attr.colspan]="tableDisplayedColumns.length">
						<div class="example-element-detail overflow-hidden flex"
						     [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
							@defer {
								<table class="w-full table-fixed border-collapse">
									<tbody>
										@for (detailElement of element[config?.detailsKey]; track detailElement) {
											<tr>
												@for (detailColumn of displayedColumns; track detailColumn.key; let detailRowIndex = $index) {
													<td [style.width.px]="getHeaderColumnWidth(detailColumn.key, element)"
													    [class]="detailColumn?.classGetter ? detailColumn.classGetter((detailColumn | CommonLayoutTableDetailGetter:
												    detailElement: detailRowIndex: dataSource), element, detailRowIndex) : ''">
														<div class="flex w-full h-full justify-end overflow-hidden text-overflow-ellipsis whitespace-nowrap">
															@switch (detailColumn.detailColumnType ?? detailColumn.columnType) {
																@case (ColumnType.Translation) {
																	{{ (detailColumn | CommonLayoutTableDetailGetter: detailElement: detailRowIndex: dataSource) | translate }}
																}
																@case (ColumnType.Price) {
																	{{
																		(detailColumn | CommonLayoutTableDetailGetter: detailElement: detailRowIndex: dataSource) | currency:
																			'EUR' :
																			'symbol' :
																			'1.2-2'
																	}}
																}
																@case (ColumnType.Percent) {
																	{{ (detailColumn | CommonLayoutTableDetailGetter: detailElement: detailRowIndex: dataSource) | percent:
																	'1.2-2' }}
																}
																@case (ColumnType.Text) {
																	{{ detailColumn | CommonLayoutTableDetailGetter: detailElement: detailRowIndex: dataSource }}
																}
																@case (ColumnType.Custom) {
																	<ng-container *ngTemplateOutlet="detailColumn?.templateRef; context: { data: detailColumn |
																CommonLayoutTableDetailGetter: detailElement: detailRowIndex: dataSource, info: detailColumn.info }"></ng-container>
																}
															}
														</div>
													</td>
												}
											</tr>
										}
									</tbody>
								</table>
							}
						</div>
					</td>
				</ng-container>

				@if (tableSecondaryGroupDisplayedColumns.length > 0) {
					<tr mat-header-row *matHeaderRowDef="tableSecondaryGroupDisplayedColumns"></tr>
				}

				<tr mat-header-row class="common-material-table-row" *matHeaderRowDef="tableDisplayedColumns"></tr>
				<tr mat-row *matRowDef="let element; columns: tableDisplayedColumns; let dataIndex = dataIndex"
				    class="example-element-row"
				    (click)="toggleRow(element, $event)"
				    [class.cursor-pointer]="config?.detailsKey"
				    [class.example-expanded-row]="expandedElement === element">
				</tr>
				@if (config.detailsKey) {
					<tr mat-row *matRowDef="let element; columns: ['expandedDetail']"
					    [class.expanded]="element === expandedElement"
					    class="example-detail-row"></tr>
				}
				<tr mat-footer-row *matFooterRowDef="tableDisplayedColumns" class="custom-footer"></tr>
			</table>
		</div>
	</div>
} @else {
	Loading ...
}