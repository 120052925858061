import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, effect, inject, Injector, OnInit, Signal, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Range } from '@libs/dash/core/entity';
import { HistoryLogsModule, ReceiptsSynthComponent } from '@libs/dash/features/v1';
import { ExportOptionType, LayoutFilterService } from '@libs/dash/features/v2';
import {
	ColumnType,
	CommonLayoutTableComponent,
	CommonSelectOption,
	CommonTableColumnGroup,
	CommonTableConfig,
} from '@libs/shared/modules/common-components';
import { FilterDataPipe } from './filter-data.pipe';

const GENERAL_SESSION_REPORTS_COLUMNS = {
	Restaurant: 'restaurant',
	Cashier: 'cashier',
	Number: 'sessionCount',
	Declared: 'declared',
	Discount: 'discount',
	Percent: 'percent',
	Real: 'real',
	Gap: 'gap',
	CancelsAmount: 'cancelsAmount',
	CancelsCount: 'cancelsCount',
	CancelsPercent: 'cancelsPercent',
	Refund: 'refund',
	Withdrawn: 'withdrawn',
};

@Component({
	selector: 'general-sessions-tab',
	standalone: true,
	imports: [CommonModule, CommonLayoutTableComponent, HistoryLogsModule, FilterDataPipe],
	templateUrl: './general-sessions-tab.component.html',
	styleUrl: './general-sessions-tab.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralSessionsTabComponent extends ReceiptsSynthComponent implements OnInit {
	private readonly injector: Injector = inject(Injector);
	private readonly dr: DestroyRef = inject(DestroyRef);
	private readonly layoutFilterServiceService: LayoutFilterService = inject(LayoutFilterService);

	selectedVendors: Signal<string[]> = computed(() =>
		this.layoutFilterServiceService.filters?.receipts ? this.layoutFilterServiceService.filters?.receipts() : null
	);

	tableGroups: CommonTableColumnGroup[] = [
		{
			titleKey: 'receipts-synth.revenue',
			columns: [
				GENERAL_SESSION_REPORTS_COLUMNS.Declared,
				GENERAL_SESSION_REPORTS_COLUMNS.Discount,
				GENERAL_SESSION_REPORTS_COLUMNS.Percent,
			],
		},
		{
			titleKey: 'receipts-synth.real_revenue',
			columns: [GENERAL_SESSION_REPORTS_COLUMNS.Real],
		},
		{
			titleKey: 'receipts-synth.gap',
			columns: [GENERAL_SESSION_REPORTS_COLUMNS.Gap],
		},
		{
			titleKey: 'receipts-synth.cancels',
			columns: [
				GENERAL_SESSION_REPORTS_COLUMNS.CancelsAmount,
				GENERAL_SESSION_REPORTS_COLUMNS.CancelsCount,
				GENERAL_SESSION_REPORTS_COLUMNS.CancelsPercent,
			],
		},
		{
			titleKey: 'receipts-synth.refunds',
			columns: [GENERAL_SESSION_REPORTS_COLUMNS.Refund],
		},
		{
			titleKey: 'receipts-synth.withdrawn',
			columns: [GENERAL_SESSION_REPORTS_COLUMNS.Withdrawn],
		},
	];

	dataSig = toSignal<any>(this.viewData$, { injector: this.injector });

	tableConfig: CommonTableConfig<any> = {
		titleKey: 'history-logs-obj.receipts-synth',
		scrollable: true,
		columns: [
			{
				key: GENERAL_SESSION_REPORTS_COLUMNS.Restaurant,
				headerLabelKey: 'receipts-synth.restaurant',
				alignment: 'left',
				columnType: ColumnType.Text,
				width: '200px',
			},
			{
				key: GENERAL_SESSION_REPORTS_COLUMNS.Cashier,
				headerLabelKey: 'receipts-synth.cashier',
				columnType: ColumnType.Text,
				alignment: 'left',
				totalColumnType: ColumnType.Translation,
				totalGetter: () => 'receipts-synth.total',
			},
			{
				key: GENERAL_SESSION_REPORTS_COLUMNS.Number,
				headerLabelKey: 'receipts-synth.nb_session_new',
				alignment: 'right',
				columnType: ColumnType.Text,
				totalGetter: () => this.dataSig()?.total?.sessionCount,
			},
			{
				key: GENERAL_SESSION_REPORTS_COLUMNS.Declared,
				headerLabelKey: 'receipts-synth.declared_amount',
				alignment: 'right',
				columnType: ColumnType.Price,
				valueGetter: (_, row: any) => row.revenue.declared,
				totalGetter: () => this.dataSig()?.total?.revenue?.declared,
			},
			{
				key: GENERAL_SESSION_REPORTS_COLUMNS.Discount,
				headerLabelKey: 'receipts-synth.reductions',
				alignment: 'right',
				columnType: ColumnType.Price,
				valueGetter: (_, row: any) => row.revenue.discount,
				totalGetter: () => this.dataSig()?.total?.revenue?.discount,
			},
			{
				key: GENERAL_SESSION_REPORTS_COLUMNS.Percent,
				headerLabelKey: 'receipts-synth.percent_reductions',
				alignment: 'right',
				columnType: ColumnType.Percent,
				valueGetter: (_, row: any) => row.revenue.percent,
				totalGetter: () => this.dataSig()?.total?.revenue?.percent,
			},
			{
				key: GENERAL_SESSION_REPORTS_COLUMNS.Real,
				headerLabelKey: 'receipts-synth.amount',
				alignment: 'right',
				columnType: ColumnType.Price,
				width: '150px',
				valueGetter: (_, row: any) => row.revenue.real,
				totalGetter: () => this.dataSig()?.total?.revenue?.real,
			},
			{
				key: GENERAL_SESSION_REPORTS_COLUMNS.Gap,
				headerLabelKey: 'receipts-synth.amount',
				alignment: 'right',
				columnType: ColumnType.Price,
				width: '150px',
				valueGetter: (_, row: any) => row.revenue.gap,
				totalGetter: () => this.dataSig()?.total?.revenue?.gap,
			},
			{
				key: GENERAL_SESSION_REPORTS_COLUMNS.CancelsAmount,
				headerLabelKey: 'receipts-synth.amount',
				alignment: 'right',
				columnType: ColumnType.Price,
				valueGetter: (_, row: any) => row.cancels.amount,
				totalGetter: () => this.dataSig()?.total?.cancels?.amount,
			},
			{
				key: GENERAL_SESSION_REPORTS_COLUMNS.CancelsCount,
				headerLabelKey: 'receipts-synth.number_new',
				alignment: 'right',
				columnType: ColumnType.Text,
				valueGetter: (_, row: any) => row.cancels.count,
				totalGetter: () => this.dataSig()?.total?.cancels?.count,
			},
			{
				key: GENERAL_SESSION_REPORTS_COLUMNS.CancelsPercent,
				headerLabelKey: 'receipts-synth.percent_cancels',
				alignment: 'right',
				columnType: ColumnType.Percent,
				valueGetter: (_, row: any) => row.cancels.percent,
				totalGetter: () => this.dataSig()?.total?.cancels?.percent,
			},
			{
				key: GENERAL_SESSION_REPORTS_COLUMNS.Refund,
				headerLabelKey: 'receipts-synth.amount',
				alignment: 'right',
				columnType: ColumnType.Price,
				width: '150px',
				valueGetter: (_, row: any) => row.refund,
				totalGetter: () => this.dataSig()?.total?.refund,
			},
			{
				key: GENERAL_SESSION_REPORTS_COLUMNS.Withdrawn,
				alignment: 'right',
				headerLabelKey: 'receipts-synth.amount',
				columnType: ColumnType.Price,
				width: '150px',
				valueGetter: (_, row: any) => row.withdrawn,
				totalGetter: () => this.dataSig()?.total?.withdrawn,
			},
		],
	};

	ngOnInit(): void {
		effect(
			() => {
				const range = this.layoutFilterServiceService?.range();
				const restaurant = this.layoutFilterServiceService?.filters?.restaurant();
				if (range && restaurant) {
					this.dateRange.setValue({ from: range.from.toJSDate(), to: range.to.toJSDate() });
					this.setPeriod(Range.Period);
				}
			},
			{ injector: this.injector, allowSignalWrites: true }
		);

		effect(
			() => {
				const results = this.dataSig();
				if (results && results.data.length > 0) {
					let options = [];
					results.data.forEach((item: any) => {
						if (!options.some((i) => i === item.cashier)) {
							options.push(item.cashier);
						}
					});
					this.layoutFilterServiceService.registerFilterOptions(
						'receipts',
						signal(
							options.map(
								(i: any) =>
									({
										value: i,
										label: i,
									}) as CommonSelectOption
							)
						)
					);
				}
			},
			{ injector: this.injector, allowSignalWrites: true }
		);

		this.layoutFilterServiceService.export
			.pipe(takeUntilDestroyed(this.dr))
			.subscribe((selectedOptions: ExportOptionType[]) => this._exportData(selectedOptions));
	}

	private _exportData(selectedOptions: ExportOptionType[]) {
		if (selectedOptions.length === 0) {
			return;
		}
		for (const option of selectedOptions) {
			switch (option) {
				case ExportOptionType.PDF:
					this.download('pdf');
					break;
				case ExportOptionType.CSV:
					this.download('csv');
					break;
				case ExportOptionType.XLSX:
					this.exportXLSX();
					break;
			}
		}
	}
}
