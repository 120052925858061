import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppRestaurantInfoModel } from '../../../../../models';

import { IFeatureRestaurantManagementState } from '../interfaces';

export const selectAppState = createFeatureSelector<IFeatureRestaurantManagementState>('restaurant-management');

export const getRestaurantListData = createSelector(selectAppState, (state) => state.data.map((item) => new AppRestaurantInfoModel(item)));

export const getSpecificRestaurantData = createSelector(selectAppState, (state, id: string) =>
	state.data.find((restaurant) => restaurant.id === id)
);

export const isLoading = createSelector(selectAppState, (state) => state.loading);
