import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Signal } from '@angular/core';
import { DttExportModule, SourceType } from '@libs/dash/features/v1';
import { DttExportGroupType, ExportOption, ExportOptionType, LayoutFilterService } from '@libs/dash/features/v2';
import { BkfCumulWrapperComponent } from '../../partial-components/cumul-wrapper/bkf-cumul-wrapper.component';
import { BkfRestoWrapperComponent } from '../../partial-components/resto-wrapper/bkf-resto-wrapper.component';

@Component({
	selector: 'bkc',
	standalone: true,
	imports: [CommonModule, DttExportModule, BkfCumulWrapperComponent, BkfRestoWrapperComponent],
	templateUrl: './bkc.component.html',
	styleUrl: './bkc.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BkcComponent {
	private readonly layoutFilterServiceService: LayoutFilterService = inject(LayoutFilterService);
	groupTypeSig: Signal<DttExportGroupType> = this.layoutFilterServiceService.filters.groupType;
	sourceTypeSig: Signal<SourceType> = this.layoutFilterServiceService.filters.source;
	groupTypes = DttExportGroupType;

	availableExportOptions: ExportOption[] = [
		{ label: ExportOptionType.PDF, selected: false, type: ExportOptionType.PDF },
		{ label: ExportOptionType.XLSX, selected: false, type: ExportOptionType.XLSX },
	];

	ngOnInit(): void {
		this.layoutFilterServiceService.setAvailableExportOptions(this.availableExportOptions);
	}
}
