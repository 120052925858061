import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IFeatureOptionsManagementState } from '../interfaces';

export const selectAppState = createFeatureSelector<IFeatureOptionsManagementState>('options-management');

export const getOptionsListData = createSelector(selectAppState, (state) => state.data);

export const getSpecificOption = createSelector(selectAppState, (state, id: number) => state.data.find((option) => option.id === id));

export const getLastCreatedOption = createSelector(selectAppState, (state) => {
		return state.data?.length ? state.data[state.data?.length - 1] : null;
});

export const isLoading = createSelector(selectAppState, (state) => state.loading);
