import { SalesChannelOption } from '@bk/price-management-common';
import { PriceListRow } from '@libs/gap/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { values } from 'lodash-es';

import { IFeaturePriceManagementState } from '../interfaces';

export const selectAppState = createFeatureSelector<IFeaturePriceManagementState>('price-management');

export const getPriceManagementData = createSelector(selectAppState, (state, item) => state[item.toLowerCase()]?.data);

export const getPriceManagementDataRows = createSelector(selectAppState, (state, item) => {
	return values(state[item.toLowerCase()]?.data);
});

export const getDefaultVatOption = createSelector(selectAppState, (state) => state.defaultVatOption);

export const getDefaultEatInOptions = createSelector(selectAppState, (state) => state.eatInVATOptions);

export const getTakeawayOptions = createSelector(selectAppState, (state) => state.takeAwayVATOptions);

export const isLoading = createSelector(selectAppState, (state, item) => state[item.toLowerCase()]?.loading);

export const getSpecificItemData = createSelector(selectAppState, (state, item) => {
	// Origina implementation
	// return state[item.itemType.toLowerCase()]?.data?.[item.id];

	// Hot patch
	let result = state[item.itemType.toLowerCase()]?.data?.[item.id] as PriceListRow;

	if (result && !result.disabledSalesChannelOptions) {
		result = { ...result };
		result.disabledSalesChannelOptions = [];

		// TODO DEV-2094 - just for testing
		if (item.id === 1) {
			result.disabledSalesChannelOptions.push(SalesChannelOption.JustEat);
			result.disabledSalesChannelOptions.push(SalesChannelOption.Deliveroo);
		}

		console.warn(
			'selector getSpecificItemData(): DEV-2094 - Waiting for API implementation. Supplying fake data for disabledSalesChannelOptions.'
		);
	}

	return result;
});
