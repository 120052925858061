import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'SafeDepositFilterData',
	standalone: true,
})
export class SafeDepositFilterDataPipe implements PipeTransform {
	transform(data: any[], values: string[]): any[] {
		if (values && values.length > 0) {
			return data.filter((item: any) => values.includes(item.manager.id.toString()));
		}
		return data;
	}
}
