import { createReducer, on } from '@ngrx/store';
import { IFeatureRestaurantManagementState } from '../interfaces';
import {
	CreateRestaurant,
	CreateRestaurantSuccess,
	DeleteRestaurant,
	DeleteRestaurantSuccess,
	LoadRestaurantList,
	LoadRestaurantListSuccess,
	LoadSpecificRestaurant,
	LoadSpecificRestaurantSuccess,
	RequestFailure,
	UpdateRestaurant,
	UpdateRestaurantSuccess,
} from './actions';

export const initialState: IFeatureRestaurantManagementState = {
	data: [],
	loading: true,
};

export const reducer = createReducer(
	initialState,
	on(LoadRestaurantList, (state) => {
		return { ...state, loading: true };
	}),
	on(LoadRestaurantListSuccess, (state, action) => {
		const restaurants = action.data.map((restaurant) => {
			const presentResto = state.data.find((singleResto) => singleResto.id === restaurant.id);
			return presentResto ? { ...presentResto, ...restaurant } : { ...restaurant };
		});
		return { ...state, data: restaurants, loading: false };
	}),
	on(LoadSpecificRestaurant, (state, action) => {
		const restaurantData = state.data.filter((item) => item.id !== action.id);
		return { data: restaurantData, loading: true };
	}),
	on(LoadSpecificRestaurantSuccess, (state, action) => {
		if (state.data.length === 0) {
			return { ...state, data: [{ ...action.data, fullyLoaded: true }], loading: false };
		}

		const nextData = state.data.map((restaurant) =>
			restaurant.id === action.id
				? {
						...restaurant,
						...action.data,
						fullyLoaded: true,
					}
				: { ...restaurant }
		);

		const isCurrentPresent = state.data.find((item) => item.id === action.id);
		return { ...state, data: isCurrentPresent ? nextData : [{ ...action.data, fullyLoaded: true }, ...nextData], loading: false };
	}),
	on(CreateRestaurant, (state, action) => {
		return { ...state, loading: true };
	}),
	on(CreateRestaurantSuccess, (state, action) => {
		return { ...state, loading: false };
	}),
	on(UpdateRestaurant, (state, action) => {
		return { ...state, loading: true };
	}),
	on(UpdateRestaurantSuccess, (state, action) => {
		return { ...state, loading: false };
	}),
	on(DeleteRestaurant, (state, action) => {
		return { ...state, loading: true };
	}),
	on(DeleteRestaurantSuccess, (state, action) => {
		return { ...state, loading: false };
	}),
	on(RequestFailure, (state, action) => {
		return { ...state, loading: false };
	})
);
