import { PERMISSIONS } from "@bk/frontend-common";
import { PermissionLevelApplyDefine, PermissionLevelManagementAdmin, PermissionLevelReadWrite, PermissionsInfo } from '@gap/api-client';
import cloneDeep from 'lodash-es/cloneDeep';

export class PermissionsModel<T> {
	// any because of stupid openapi
	// must investigate to fix
	name: string;
	level: PERMISSIONS;
	levels: Array<PermissionLevelReadWrite | PermissionLevelManagementAdmin | PermissionLevelApplyDefine>;
	properties?: T;

	constructor(init?: Partial<PermissionsModel<T>>) {
		if (init?.levels) {
			const availableLevels = [null, ...init?.levels];
			Object.assign(this, cloneDeep({ ...init, levels: availableLevels }));
		} else {
			Object.assign(this, cloneDeep(init));
		}
	}
}

export class PermissionsInfoModel implements PermissionsInfo {
	levels: Array<PermissionLevelReadWrite | PermissionLevelManagementAdmin | PermissionLevelApplyDefine>;

	constructor(init?: Partial<PermissionsInfoModel>) {
		Object.assign(this, cloneDeep(init));
	}
}
