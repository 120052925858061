import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import * as api from '@dki/api-client';
import { Range } from '@libs/dash/core/entity';
import { ReportOptions, generateXlsx } from '@libs/dash/shared';
import { TranslateService } from '@libs/shared/modules/i18n';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isEqual, xorWith } from 'lodash-es';
import { DateTime } from 'luxon';
import { distinctUntilChanged, filter, withLatestFrom } from 'rxjs/operators';
import { SYNTHESIS_REPORT_FACADE } from '../facade/synthesis-report-facade.injection.token';
import { SynthesisReportServiceProvider } from '../facade/synthesis-report-facade.provider.interface';

@UntilDestroy()
@Component({
	selector: 'dk-synthesis-report',
	templateUrl: './synthesis-report.component.html',
	styleUrls: ['./synthesis-report.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SynthesisReportComponent implements OnInit {
	localisedTexts$ = this.translateService.selectTranslation('synthesis-report');
	@Input() visible = false;
	dateRange = new FormGroup({
		from: new FormControl(DateTime.now().startOf(Range.Day).toJSDate()),
		to: new FormControl(DateTime.now().endOf(Range.Day).toJSDate()),
	});

	isLoading = this.synthesisReportService.isLoading$;
	isProcessing = true;

	synthesisEnabled = this.synthesisReportService.synthesisEnabled;
	selectedRestaurants: api.Restaurant[] = [];

	constructor(
		private translateService: TranslateService,
		@Inject(SYNTHESIS_REPORT_FACADE) protected readonly synthesisReportService: SynthesisReportServiceProvider
	) {}

	ngOnInit(): void {
		this.synthesisReportService.selectedRestaurants$
			.pipe(
				filter(() => this.visible),
				withLatestFrom(this.synthesisReportService.isLoadingSavedRestaurant$),
				filter(([a, loading]) => {
					return !loading;
				}),
				distinctUntilChanged(this.customComparator),
				untilDestroyed(this)
			)
			.subscribe(() => {
				this.synthesisReportService.fetch();
			});
	}

	singleDaySelection() {
		const from = this.dateRange.controls.from.value as Date;
		const to = this.dateRange.controls.to.value as Date;
		return from.getDate() === to.getDate() && from.getMonth() === to.getMonth() && from.getFullYear() === to.getFullYear();
	}

	setPeriod(period?: string) {
		const today = DateTime.now();
		let from = DateTime.fromJSDate(this.dateRange.controls.from.value),
			to = DateTime.fromJSDate(this.dateRange.controls.to.value);
		switch (period) {
			case Range.Today:
				from = today.startOf(Range.Day);
				to = today.endOf(Range.Day);
				break;
			case Range.Week:
				from = today.startOf(Range.Week);
				to = today.endOf(Range.Week);
				break;
			case Range.Month:
				from = today.startOf(Range.Month).startOf(Range.Day);
				to = today.endOf(Range.Month);
				break;
			case Range.Period:
				to = !this.dateRange.controls.to.value ? from.endOf(Range.Day) : to.endOf(Range.Day);
				break;
		}
		this.dateRange.setValue({ from: from.toJSDate(), to: to.toJSDate() });
		this.synthesisReportService.fetch(from, to);
	}

	customComparator(prev, curr) {
		return (
			xorWith(
				prev.map((rest) => rest.id),
				curr.map((rest) => rest.id),
				isEqual
			).length === 0
		);
	}

	downloadXlsx() {
		this.localisedTexts$
			.pipe(withLatestFrom(this.synthesisReportService.synthesisReport$), untilDestroyed(this))
			.subscribe(([i18n, report]) => {
				const synthesisReportOptions: ReportOptions = {
					setupHeaders: (sheet) => {
						const headers = ['Category', 'Value'];
						const headerRow = sheet.addRow(headers);
						headerRow.eachCell((cell) => {
							cell.font = { bold: true };
						});
						return headerRow;
					},

					prepareDataRows: (sheet) => {},

					generateFileName: () => {
						const from = DateTime.fromJSDate(this.dateRange.controls.from.value).toFormat('dd-LL-yyyy');
						const to = DateTime.fromJSDate(this.dateRange.controls.to.value).toFormat('dd-LL-yyyy');
						return `SynthesisReport_${from}_${to}.xlsx`;
					},
				};

				const from = DateTime.fromJSDate(this.dateRange.controls.from.value).toFormat('dd/LL/yyyy');
				const to = DateTime.fromJSDate(this.dateRange.controls.to.value).toFormat('dd/LL/yyyy');
				const detailText = `Restaurant${this.selectedRestaurants.length > 1 ? 's' : ''} : ${this.selectedRestaurants.join(', ')}\nPeriode : ${from} - ${to}`;

				generateXlsx(i18n.synthesisReport, detailText, synthesisReportOptions);
			});
	}
}
