<merim-common-screen-title [title]="'reports.title'">
	<date-picker-new
		(dateSelection)="onDateSelection($event)"
		(selectedPeriodChange)="onDatePeriodSelection($event)"
		[selectedPeriodSig]="rangePeriod()"
		[initDate]="range()"
	></date-picker-new>
</merim-common-screen-title>

<merim-common-layout-tabs [tabs]="tabs" (contentChanged)="onTabsChanged($event)">
	<layout-filters [multiSelectRestaurant]="true" (exportSelected)="export($event)" (restaurantSelected)="setRestaurants($event)">
		@if (selectedTabId === 'products' || selectedTabId === 'operating' || selectedTabId === 'hourly') {
			<common-layout-select
				[icon]="'device_hub'"
				[label]="'sosKioskTile.salesChannels.label'"
				[search]="true"
				[selectedValue]="filters?.channels()"
				[allCheckbox]="false"
				[multiSelect]="true"
				(selectionChange)="channelSelected($event)"
				[options]="filtersOptions?.channels ? filtersOptions?.channels() : []"
			>
			</common-layout-select>
		}

		@if (selectedTabId === 'weekly') {
			<div class="h-[39px] flex content-center items-center border rounded border-solid px-2 border-primary-60">
				<mat-icon class="text-baseLayer-secondary min-w-6">calendar_month</mat-icon>
				<span class="px-2 text-secondary-20">{{ 'weeklyReportTile.week' | translate }}</span>
				<span class="text-secondary-20">{{ range()?.from?.toJSDate() | date: 'ww' }}</span>
			</div>
		}
	</layout-filters>
</merim-common-layout-tabs>
