import { PERMISSIONS } from "@bk/frontend-common";
import { Observable } from 'rxjs';
import { Credentials } from './credentials.model';

export interface IAuthService<MODULE, USER> {
	hasPermission: (resource: string, minimalPermissionsRequired: PERMISSIONS, module?: MODULE) => boolean;
	toDefaultRoute: () => void;
	toUnauthorizedRoute: () => void;
	showUnauthorizedMessage(): void;
	login(credentials: Credentials): Observable<USER>;
}

export abstract class AuthServiceToken implements IAuthService<unknown, unknown> {
	abstract hasPermission(resource: string, minimalPermissionsRequired: PERMISSIONS, module?: unknown): boolean;

	abstract toDefaultRoute(): void;

	abstract toUnauthorizedRoute(): void;

	abstract showUnauthorizedMessage(): void;

	abstract login(credentials: Credentials): Observable<unknown>;
}
