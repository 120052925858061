import { AppTagInfo } from '@libs/gap/models';
import { createAction, props } from '@ngrx/store';

export const LoadTagList = createAction('[TagManagement] Loading restaurant tag list');

export const LoadTagListSuccess = createAction('[TagManagement] Loading tag list success', props<{ data: AppTagInfo[] }>());

export const LoadSpecificTag = createAction('[TagManagement] Loading specific tag', props<{ id: number }>());

export const LoadSpecificTagSuccess = createAction(
	'[TagManagement] Loading specific tag success',
	props<{
		id: number;
		data: AppTagInfo;
	}>()
);

export const CreateTag = createAction('[TagManagement] Create new tag', props<{ data: AppTagInfo }>());

export const CreateTagSuccess = createAction('[TagManagement] Create new tag success', props<{ data: AppTagInfo }>());

export const UpdateTag = createAction('[TagManagement] Updates specific tag', props<{ id: number; data: AppTagInfo }>());

export const UpdateTagSuccess = createAction('[TagManagement] Updates specific tag success', props<{ id: number; data: AppTagInfo }>());

export const DeleteTag = createAction('[TagManagement] Delete specific tag', props<{ id: number }>());

export const DeleteTagSuccess = createAction('[TagManagement] Delete specific tag success');

export const RequestFailure = createAction('[TagManagement] Handle request error');
