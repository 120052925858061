import { Inject, InjectionToken } from '@angular/core';
import {
	BKOrderNewReglementCustomDefinedStatus,
	BKOrderNewReglementUserMessageStatus,
	BKOrderPayLineResultEnum,
	getPaymentLineUuidFromTicket,
} from '@bk/fullstack-common';
import { CsiResponses, ICsiTicket } from '@bk/jscommondatas';
import { DeclaredOrderResponse } from '@bk/price-management-common';
import { INewReglementResultResponse, INewReglementUserMessageResponse } from '@libs/shared/interfaces';
import { LOCAL_STORAGE_KEYS, ORDER_UUID } from '@libs/shared/models';
import { readFromLocalStorage } from '@libs/shared/utils';
import { Shar3dUtils } from '@shar3d/shar3d-utils';
import { IOrderCompleteData } from '@shared/models';
import { Observable } from 'rxjs';
import { ConfigurationService } from './configuration.service';
import { MerimLogger } from '@bk/frontend-common';

const ConfigurationServiceParam = new InjectionToken<ConfigurationService<any>>('Clerk service configuration');

export class ClerkSseService {
	constructor(@Inject(ConfigurationServiceParam) private readonly _configurationService: ConfigurationService<any>) {}

	createEventSource(
		url: string,
		order: IOrderCompleteData,
		inputData: DeclaredOrderResponse,
		profitCenter: string,
		modeReglement: string
	): Observable<Partial<INewReglementResultResponse> | INewReglementUserMessageResponse> {
		const numCommande = inputData.response?.[0] || '';
		const originName = inputData.response?.[1] || '';
		const unitaire = inputData.response[inputData.response.length - 1] || 0;
		const auth = readFromLocalStorage(LOCAL_STORAGE_KEYS.CLERK_JWT);

		const gets: { [key: string]: string } = {};
		gets['numCommande'] = numCommande.toString(); //Integer	Numéro de la commande
		gets['originName'] = originName.toString(); //String	Nom du terminal à l’origine de la commande
		gets['quantite'] = '1'; //Decimal	Quantité
		gets['numConfiguration'] = modeReglement; //TODO - get correct mode reglement based on the selected value, should be passed
		gets['unitaire'] = unitaire.toString(); //Decimal	Montant unitaire
		gets['codeVendeur'] = order.user.id.toString(); //Integer	Code du vendeur
		gets['nomVendeur'] = order.user.login; //String	Nom du vendeur
		gets['codeClef'] = order.user.id.toString(); //Integer	Code de la clef
		gets['nomClef'] = order.user.login; //String	Nom de la clef
		gets['nomManager'] = order.manager.login;
		gets['codeManager'] = order.manager.id.toString();
		gets[ORDER_UUID] = order.orderUUID;
		const paymentInfo = {
			profit: profitCenter,
		};
		gets['infoPaiementDynamic'] = JSON.stringify(paymentInfo);

		if (auth) {
			gets['jwt'] = auth as string;
		}

		const eventSource = new EventSource(url + '?' + Shar3dUtils.object2URLEncoded(gets));

		MerimLogger.debug('New reglement: creating SSE for order', order.orderUUID);
		return new Observable((observer) => {
			eventSource.onmessage = (e) => {
				MerimLogger.debug('New reglement: Received message from clerk: ' + JSON.stringify(e.data), order.orderUUID);
			};

			eventSource.addEventListener('usermessage', (e: any) => {
				MerimLogger.debug(`New reglement: received SSE message: ${JSON.stringify(e?.data)}`, order.orderUUID);

				let content = e.data;
				if (typeof content === 'string') {
					try {
						content = JSON.parse(content);
					} catch (e) {
						MerimLogger.error(`New reglement: could not parse SSE user message: ${JSON.stringify(e)}`, order.orderUUID);
					}
				} else {
					MerimLogger.debug(`New reglement: SSE Content is not of type string: ${JSON.stringify(e)}`, order.orderUUID);
				}

				observer.next({
					status: BKOrderNewReglementUserMessageStatus.USER_MESSAGE,
					content: content,
				});
			});

			eventSource.addEventListener('result', (response: any) => {
				let data = null;
				MerimLogger.debug(`New reglement: received SSE result message: ${JSON.stringify(response?.data)}`, order.orderUUID);

				try {
					data = JSON.parse(response.data);
				} catch (e) {
					MerimLogger.error(`New reglement: could not parse SSE result: ${JSON.stringify(e)}`, order.orderUUID);
					observer.error({ status: BKOrderPayLineResultEnum.ERROR });
				}
				const success: boolean =
					data.code &&
					(data.code === CsiResponses.STATUS_TICKET_NON_SOLDE ||
						data.code === CsiResponses.STATUS_TICKET_SOLDE ||
						data.code === CsiResponses.STATUS_TICKET_RENDU);

				let orb = order.orb;
				if (success) {
					if (data.code === CsiResponses.STATUS_TICKET_NON_SOLDE) {
						const ticket: ICsiTicket = data.response[1];
						const paymentLineUuid = getPaymentLineUuidFromTicket(ticket);
						const cbToken: string = data.response[2]?.cardToken;
						// TODO: order pay line
						//const orderPayLine = new BKOrderPayLineResult(BKOrderPayLineResultEnum.OK_NOT_COMPLETE, payLine, parseFloat(response.response));
						MerimLogger.error(`New reglement: payment not successful: ${JSON.stringify(response)}`, order.orderUUID);
						observer.error({
							status: BKOrderPayLineResultEnum.OK_NOT_COMPLETE,
							paymentLineUuid: paymentLineUuid,
							orb: orb,
							cbToken: cbToken,
						});
						return;
					} else if (!data.response) {
						MerimLogger.error(`New reglement: SSE Response missing: ${JSON.stringify(response)}`, order.orderUUID);
						observer.error({ status: BKOrderPayLineResultEnum.ERROR });
						return;
					} else if (data.alienId != null && typeof data.alienId === 'number') {
						orb = data.alienId;
					}

					const responseData: Array<any> = <Array<any>>data.response;
					if (
						(data.code === CsiResponses.STATUS_TICKET_SOLDE && responseData.length < 2) ||
						(data.code === CsiResponses.STATUS_TICKET_RENDU && responseData.length < 4)
					) {
						observer.error({ status: BKOrderPayLineResultEnum.ERROR });
						return;
					}

					const ticket: ICsiTicket = CsiResponses.STATUS_TICKET_SOLDE ? responseData[2] : responseData[4];
					const paymentLineUuid = getPaymentLineUuidFromTicket(ticket);
					const cbToken: string = data.response[3]?.cardToken;

					const ticketNumber = responseData[0];
					const srcTicket = responseData[1];

					const difference: number = data.code === CsiResponses.STATUS_TICKET_RENDU ? responseData[3] : 0;
					const differenceType = data.code === CsiResponses.STATUS_TICKET_RENDU ? responseData[2] : '';
					const commandeNumber = responseData[2]?.commandeNumber ? responseData[2]?.commandeNumber : '';
					// TODO: payline
					//const okResult = new BKOrderPayLineResult(BKOrderPayLineResultEnum.OK_COMPLETE, payLine, difference, differenceType);
					observer.next({
						status: BKOrderPayLineResultEnum.OK_COMPLETE,
						orb,
						ticketNumber,
						srcTicket,
						difference,
						differenceType,
						paymentLineUuid,
						cbToken,
						commandeNumber,
					});
				} else {
					const status =
						data.code === CsiResponses.STATUS_PAYMENT_CAPTURE_FAILED
							? BKOrderPayLineResultEnum.PAYMENT_CAPTURE_ERROR
							: BKOrderPayLineResultEnum.ERROR;
					observer.error({ status });
				}
				eventSource.close();
			});

			eventSource.onerror = (e) => {
				MerimLogger.error(`New reglement: SSE error. Received: ${JSON.stringify(e)}`, order.orderUUID);
				observer.error({
					...e,
					status: BKOrderNewReglementCustomDefinedStatus.SERVER_ERROR,
				});
				eventSource.close();
			};

			return () => {
				eventSource.close();
			};
		});
	}
}
