import { CommonModule } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	computed,
	DestroyRef,
	effect,
	inject,
	Injector, OnDestroy,
	Signal,
	signal
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Range } from '@libs/dash/core/entity';
import { BUSINESS_DAY_START_HOUR, HourlyInternalDataTable, HourlySalesTileComponent, ReportsModule } from '@libs/dash/features/v1';
import { ExportOption, ExportOptionType, LayoutFilterService } from '@libs/dash/features/v2';
import {
	ColumnType,
	CommonLayoutTableComponent,
	CommonTableColumnConfig,
	CommonTableColumnGroup,
	CommonTableConfig,
} from '@libs/shared/modules/common-components';
import { tap } from 'rxjs';
import { HourlyReportTableConfigFilterPipe } from './hourly-report-table-config-filter.pipe';
import { HourlyReportTableGroupsFilterPipe } from './hourly-report-table-groups-filter.pipe';

@Component({
	selector: 'hourly-reports-tab',
	standalone: true,
	imports: [CommonModule, ReportsModule, CommonLayoutTableComponent, HourlyReportTableConfigFilterPipe, HourlyReportTableGroupsFilterPipe],
	templateUrl: './hourly-reports-tab.component.html',
	styleUrl: './hourly-reports-tab.component.scss',
	providers: [HourlyReportTableConfigFilterPipe],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HourlyReportsTabComponent extends HourlySalesTileComponent implements AfterViewInit, OnDestroy {
	private readonly configPipe: HourlyReportTableConfigFilterPipe<any> = inject(HourlyReportTableConfigFilterPipe);
	private readonly injector: Injector = inject(Injector);
	private readonly dr: DestroyRef = inject(DestroyRef);
	private readonly layoutFilterServiceService: LayoutFilterService = inject(LayoutFilterService);

	selectedChannels: Signal<string[]> = computed(() =>
		this.layoutFilterServiceService.filters?.channels ? this.layoutFilterServiceService.filters?.channels() : null
	);

	dataSig = toSignal<{ dataTable: HourlyInternalDataTable; i18n: any }>(this.viewData$, {
		injector: this.injector,
	});

	tableDataSig = computed<any[]>(() => {
		if (this.dataSig() && this.dataSig().dataTable) {
			const modifyObject = (obj) => {
				let final = { ...obj };
				this.dataSig().dataTable.columns.forEach((column, index) => {
					final[column + '__cumulative'] = obj.values[index].cumulative_value;
					final[column + '__CA'] = obj.values[index].value;
					final[column + '__NB'] = obj.values[index].count;
					final[column + '__MOY'] = obj.values[index].mean_value;
				});
				return final;
			};

			const mappedArray = Object.values(this.dataSig().dataTable.rowGroups).map((group) => {
				const parentObject = Object.values(group).filter((g) => g.isParent)[0];
				const details = Object.values(group)
					.filter((g) => !g.isParent)
					.map((g) => modifyObject(g));
				return {
					...modifyObject(parentObject),
					details: details,
				};
			});

			return [
				...mappedArray
					.filter((item) => Number(item.hourFrom) > BUSINESS_DAY_START_HOUR)
					.sort((a, b) => Number(a.hourFrom) - Number(b.hourFrom)),

				...mappedArray
					.filter((item) => Number(item.hourFrom) <= BUSINESS_DAY_START_HOUR)
					.sort((a, b) => Number(a.hourFrom) - Number(b.hourFrom)),
			];
		}
		return [];
	});

	tableGroupsSig = computed<CommonTableColumnGroup[]>(() => {
		if (this.dataSig() && this.dataSig().dataTable) {
			return this.dataSig().dataTable.columns.map((column) => {
				return {
					titleKey: 'channels.' + [column],
					columns: [column + '__CA', column + '__NB', column + '__MOY', column + '__cumulative'],
					colspan: 4
				};
			});
		}
		return null;
	});

	tableConfigSig = computed<CommonTableConfig<any>>(() => {
		if (this.dataSig() && this.dataSig().dataTable) {
			return {
				titleKey: 'reports.hourlySalesTile',
				detailsKey: 'details',
				scrollable: true,
				columns: [
					{
						key: 'hour',
						columnType: ColumnType.Text,
						headerLabelKey: 'sosKioskTile.dataTable.slots',
						alignment: 'left',
						width: '150px',
						valueGetter: (cell: unknown, row: any) => `${row.hourFrom.padStart(2, '0')}h - ${row.hourTo.padStart(2, '0')}h`,
						detailGetter: (cell: unknown, row: any) => `${row.hourFrom} - ${row.hourTo}`,
					},
					...this.dataSig().dataTable.columns.reduce((acc: CommonTableColumnConfig<any>[], column, index) => {
						acc.push(
							{
								key: column + '__CA',
								columnType: ColumnType.Price,
								headerLabelKey: 'CA',
								alignment: 'right',
								width: '100px',
								totalGetter: () => this.dataSig()?.dataTable?.totals[index]?.value,
							},
							{
								key: column + '__NB',
								columnType: ColumnType.Text,
								headerLabelKey: 'NB',
								alignment: 'right',
								width: '70px',
								totalGetter: () => this.dataSig()?.dataTable?.totals[index]?.count,
							},
							{
								key: column + '__MOY',
								columnType: ColumnType.Price,
								headerLabelKey: 'MOY',
								alignment: 'right',
								width: '100px',
								totalGetter: () => this.dataSig()?.dataTable?.totals[index]?.mean_value,
							},
							{
								key: column + '__cumulative',
								columnType: ColumnType.Price,
								headerLabelKey: 'CUMUL',
								alignment: 'right',
								width: '100px',
								totalGetter: () => this.dataSig()?.dataTable?.totals[index]?.cumulative_value,
								classGetter: () => '!bg-neutral-80',
							}
						);
						return acc;
					}, []),
				],
			};
		}
		return null;
	});

	ngAfterViewInit(): void {
		this.layoutFilterServiceService.registerFilterOptions('channels', signal([]));

		effect(
			() => {
				const config = this.tableConfigSig();
				if (config) {
					this.layoutFilterServiceService.registerFilterOptions(
						'channels',
						signal(
							this.dataSig().dataTable.columns.map((column) => ({
								labelKey: 'channels.' + column,
								value: column,
							}))
						)
					);
				}
			},
			{ injector: this.injector, allowSignalWrites: true }
		);

		effect(
			() => {
				const range = this.layoutFilterServiceService?.range();
				const restaurant = this.layoutFilterServiceService?.filters?.restaurant();
				if (range && restaurant) {
					this.dateRange.setValue({ from: range.from.toJSDate(), to: range.to.toJSDate() });
					this.setPeriod(Range.Period);
				}
			},
			{ injector: this.injector, allowSignalWrites: true }
		);

		const availableExportOptions: ExportOption[] = [
			{ label: ExportOptionType.CSV, selected: false, type: ExportOptionType.CSV },
			{ label: ExportOptionType.PDF, selected: false, type: ExportOptionType.PDF },
			{ label: ExportOptionType.XLSX, selected: false, type: ExportOptionType.XLSX },
		];
		this.layoutFilterServiceService.setAvailableExportOptions(availableExportOptions);
		this.layoutFilterServiceService.export
			.pipe(takeUntilDestroyed(this.dr))
			.subscribe((selectedOptions: string[]) => this._exportData(selectedOptions));
	}

	ngOnDestroy(): void {
		this.tableConfigSig = signal(null)
		this.tableDataSig = signal([]);
	}

	private _exportData(selectedOptions: string[]) {
		if (selectedOptions.length === 0) {
			return;
		}
		for (const option of selectedOptions) {
			switch (option) {
				case ExportOptionType.PDF:
					this.downloadPDF();
					break;
				case ExportOptionType.CSV:
					this.downloadCsv();
					break;
				case ExportOptionType.XLSX:
					this.downloadXlsx();
					break;
			}
		}
	}
}
