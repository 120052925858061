/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient } from '@angular/common/http';
import { enableProdMode } from '@angular/core';
import { IDefaultConfig } from '@libs/shared/interfaces';
import { SingleAPIConfig } from '@libs/shared/models';
import { ENVIRONMENT_ENUM } from '@shared/models';
import { mergeDeepRight } from 'ramda';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as toml from 'toml';
import { conditionalConsoleLog, trimChar, urlParam } from '../utils';
import { MerimLogger } from '@bk/frontend-common';

export class ConfigurationService<T extends IDefaultConfig> {
	/*
	 * These are properties are always present in the merged dynamic config-env.js file.
	 */

	version: string;

	build: {
		number: string;
		date: Date;
	};

	features: { [key: string]: any };

	config: T;
	config$: BehaviorSubject<T>;
	queryParameters: Record<string, string> = {};

	constructor(
		private readonly httpClient: HttpClient,
		environmentDef: T
	) {
		this.version = '';

		this.build = {
			date: new Date(),
			number: '',
		};
		this.features = {};
		this.config = environmentDef;

		this.updateConfigValuesWithParameters();
		this.config$ = new BehaviorSubject<T>(this.config);
	}

	async loadConfig(path: string = './assets/config/environment.toml'): Promise<void> {
		await this.httpClient
			.get(path, { responseType: 'text' })
			.toPromise()
			.then((tomlData: any) => {
				const data = toml.parse(tomlData);
				conditionalConsoleLog(
					`[APP]: Initial config: ${JSON.stringify(this.config, null, 2)}`,
					data?.['environment'] === ENVIRONMENT_ENUM.PROD
				);
				conditionalConsoleLog(
					`[APP]: Downloaded config: ${JSON.stringify(data, null, 2)}`,
					this.config?.['environment'] === ENVIRONMENT_ENUM.PROD
				);
				// @ts-ignore
				this.config = mergeDeepRight(this.config, data);
				this.updateConfigValuesWithParameters();
				MerimLogger.debug(`Config merged & loaded: ${JSON.stringify(this.config, null, 2)}`);
				this.config$.next(this.config);

				if (data?.environment === ENVIRONMENT_ENUM.PROD) {
					enableProdMode();
				}

				return this.config;
			})
			.catch((e) => {
				console.warn('Error downloading config: ', e);
			});
	}

	get getConfig$(): Observable<T> {
		return this.config$;
	}

	hasFeature(name: string, defaultValue?: boolean): boolean {
		return this.features?.[name] === true || defaultValue || false;
	}

	updateConfig(config: any): void {
		this.config = mergeDeepRight(this.config, config);
		this.config$.next(this.config);
	}

	/**
	 * Name can be also passed with '.' (dots) indicating nested structure
	 * @param name
	 * @param defaultValue
	 */
	getValue(name: string, defaultValue?: string | boolean | { [key: string]: any }): string | { [key: string]: any } | boolean {
		const nestedArribute = name.split('.');
		if (nestedArribute.length > 1) {
			let value = <any>this.config;
			nestedArribute.forEach((key) => {
				value = value?.[key];
			});
			return value || defaultValue;
		} else {
			const value: any = (<any>this.config)[name];
			return value || defaultValue;
		}
	}

	getBooleanValue(propertyName: string, defaultValue: boolean = false): boolean {
		return !!this.getValue(propertyName, defaultValue);
	}

	getNumericValue(propertyName: string, defaultValue?: number): number {
		const value = this.getValue(propertyName, (defaultValue || 0).toString()) as string;
		return parseFloat(value);
	}

	getApiUrl(api: string): string | undefined {
		const url: string = this.config?.['API']?.[api]?.url;

		if (url) {
			return url.length > 1 ? trimChar(url, '/') : url;
		}

		console.warn(`Url for "${api}" API not set.`);

		return undefined;
	}

	getApiPort(api: string): string | undefined {
		const port: string = this.config?.['API']?.[api]?.port;

		if (port) {
			return port.length > 1 ? port.trim() : port;
		}

		console.warn(`Port for "${api}" API not set.`);

		return undefined;
	}

	getApiNamespace(api: string): string | undefined {
		const apiNamespace: string = this.config?.['API']?.apiNamespace;

		if (apiNamespace) {
			return apiNamespace.length > 1 ? apiNamespace.trim() : apiNamespace;
		}

		console.warn(`Api Namespace for "${api}" API not set.`);

		return undefined;
	}

	getApiKey(api: string): string | undefined {
		const key: string = this.config?.['API']?.[api]?.key;

		if (key) {
			return key.length > 1 ? key.trim() : key;
		}

		console.warn(`Api Key for "${api}" API not set.`);

		return undefined;
	}

	updateConfigValuesWithParameters(): void {
		if (urlParam('be') && this.config) {
			const ws = location.protocol === 'https:' ? 'wss://' : 'ws://';
			const wsUrl = `${ws}${urlParam('be')}:4001`;

			conditionalConsoleLog(
				`[APP]: Updating websockets connection from the URL params - ${wsUrl}`,
				this.config?.['environment'] !== ENVIRONMENT_ENUM.PROD
			);
			this.config['API'].websockets.ring = { url: wsUrl };
		}

		if (urlParam('deviceId') && this.config) {
			this.config['deviceId'] = urlParam('deviceId');
		}
	}

	getApiDefinition$(api: string, specification?: string): Observable<SingleAPIConfig | undefined> {
		return this.getConfig$.pipe(
			filter((data) => !!data),
			map((data) => {
				if (specification) {
					return data['API']?.[api]?.[specification] as SingleAPIConfig;
				}

				return data['API']?.[api] as SingleAPIConfig;
			})
		);
	}
}
