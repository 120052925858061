@for (snackbar of snackbars; track snackbar.id; let i = $index) {
	@if (snackbar.show) {
		@switch (snackbar?.additionalConfiguration?.designType) {
			@case (SnackBarDesign.management) {
				<ng-container [ngTemplateOutlet]="managementSnackbar" [ngTemplateOutletContext]="{snackbar, i}"></ng-container>
			}
			@case (SnackBarDesign.default) {
				<ng-container [ngTemplateOutlet]="defaultSnackbar" [ngTemplateOutletContext]="{snackbar, i}"></ng-container>
			}
			@default {
				<ng-container [ngTemplateOutlet]="defaultSnackbar" [ngTemplateOutletContext]="{snackbar, i}"></ng-container>
			}
		}
	}
}

<ng-template #defaultSnackbar let-snackbar="snackbar" let-i="i">
	<div
		[@.disabled]="animationDisabled"
		@state
		class="snackbar-wrap"
		[ngStyle]="{ 'margin-top': 4.5 * i + 'rem' }"
		[ngClass]="[snackbar.type, class]"
	>
		@if (snackbar?.additionalConfiguration?.prefix) {
			<div class="snackbar-prefix">
				{{ snackbar.additionalConfiguration.prefix }}
			</div>
		}
		<div class="snackbar-info">
			@if (snackbar?.title) {
				<p class="snackbar-text">
					{{ snackbar.title }}
				</p>
			}
			@if (snackbar?.additionalConfiguration?.description) {
				<p class="snackbar-description">
					{{ snackbar.additionalConfiguration.description }}
				</p>
			}
		</div>
		@if (snackbar?.additionalConfiguration?.icon) {
			<div title="Dismiss" class="snackbar-icon" (click)="dismiss(snackbar.id)">
				<span class="material-icons">{{ snackbar.additionalConfiguration.icon }}</span>
			</div>
		}
		@if (!snackbar?.additionalConfiguration?.icon) {
			<div title="Dismiss" class="clickable dismiss-button" (click)="dismiss(snackbar.id)">{{ snackbar.dismissButtonText }}</div>
		}
	</div>
</ng-template>

<ng-template #managementSnackbar let-snackbar="snackbar" let-i="i">
	<div
		[@.disabled]="animationDisabled"
		@state
		class="snackbar-wrap-management rounded flex gap-2 w-[344px] p-4 justify-start items-center text-[14px] leading-5 font-normal"
		[ngClass]="[snackbar.type, class, getSnackbarTailwindClasses(snackbar.type)]"
	>
		@if (snackbar?.additionalConfiguration?.prefix) {
			<div>
				{{ snackbar.additionalConfiguration.prefix }}
			</div>
		}
		<div class="flex flex-col gap-2 grow">
			@if (snackbar?.title) {
				<span>
					{{ snackbar.title }}
				</span>
			}
			@if (snackbar?.additionalConfiguration?.description) {
				<span>
					{{ snackbar.additionalConfiguration.description }}
				</span>
			}
		</div>
		@if (snackbar?.additionalConfiguration?.icon) {
			<div title="Dismiss" class="w-6 h-6 hover" (click)="dismiss(snackbar.id)">
				<span class="material-icons">{{ snackbar.additionalConfiguration.icon }}</span>
			</div>
		}
	</div>
</ng-template>