import { AppPaymentMethodInfo } from '@libs/gap/models';
import { createAction, props } from '@ngrx/store';

export const LoadPaymentMethodList = createAction('[PaymentManagement] Loading payment method list');

export const LoadPaymentMethodListSuccess = createAction(
	'[PaymentManagement] Loading payment method list success',
	props<{
		data: AppPaymentMethodInfo[];
	}>()
);

export const LoadSpecificPaymentMethod = createAction('[PaymentManagement] Loading specific payment method', props<{ id: number }>());

export const LoadSpecificPaymentMethodSuccess = createAction(
	'[PaymentManagement] Loading specific payment method success',
	props<{
		id: number;
		data: AppPaymentMethodInfo;
	}>()
);

export const CreatePaymentMethod = createAction('[PaymentManagement] Create new payment method', props<{ data: AppPaymentMethodInfo }>());

export const CreatePaymentMethodSuccess = createAction(
	'[PaymentManagement] Create new payment method success',
	props<{
		data: AppPaymentMethodInfo;
	}>()
);

export const UpdatePaymentMethod = createAction(
	'[PaymentManagement] Updates specific payment method',
	props<{
		id: number;
		data: AppPaymentMethodInfo;
	}>()
);

export const UpdatePaymentMethodSuccess = createAction(
	'[PaymentManagement] Updates specific payment method success',
	props<{
		id: number;
		data: AppPaymentMethodInfo;
	}>()
);

export const DeletePaymentMethod = createAction('[PaymentManagement] Delete specific payment method', props<{ ids: number[] }>());

export const DeletePaymentMethodSuccess = createAction('[PaymentManagement] Delete specific payment method success');

export const RequestFailure = createAction('[PaymentManagement] Handle request error');
