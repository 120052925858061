import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, effect, inject, Injector, Input, OnInit, Signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import * as api from '@dki/api-client';
import { Range } from '@libs/dash/core/entity';
import { DttExportComponent, DttExportModule, SourceType } from '@libs/dash/features/v1';
import { equals } from 'ramda';

import { ExportOption, ExportOptionType, LayoutFilterService, LayoutRange } from '../../../layout-filter-service/layout-filter.service';
import { DttExportGroupType } from '../../time-of-service.component';
import { BkcCumulTableComponent } from '../bkc-cumul-table/bkc-cumul-table.component';
import { BkfTableCumulComponent } from '../bkf-table-cumul/bkf-table-cumul.component';

@Component({
	selector: 'bkf-cumul-wrapper',
	standalone: true,
	imports: [CommonModule, DttExportModule, BkfTableCumulComponent, BkcCumulTableComponent],
	templateUrl: './bkf-cumul-wrapper.component.html',
	styleUrl: './bkf-cumul-wrapper.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BkfCumulWrapperComponent extends DttExportComponent implements OnInit {
	private readonly layoutFilterService: LayoutFilterService = inject(LayoutFilterService);
	protected readonly injector: Injector = inject(Injector);
	private readonly dr: DestroyRef = inject(DestroyRef);
	contentViewData: Signal<{ dttReport; i18n }> = toSignal(this.viewData$, { injector: this.injector });

	dataTableMappedSig: Signal<any[]> = computed(() => {
		if (this.contentViewData() && Array.isArray(this.contentViewData().dttReport) && this.contentViewData().dttReport.length <= 7) {
			return this.contentViewData().dttReport;
		}

		return [];
	});

	@Input({ alias: 'isBKC', required: true }) set setBKC(isBKC: boolean) {
		this.isBKC = isBKC;
	}
	@Input({ required: true }) override groupType: DttExportGroupType = DttExportGroupType.CumulAll;
	@Input({ required: true }) override sourceType: SourceType = SourceType.boths;

	override ngOnInit(): void {
		super.ngOnInit();
		this.setRestaurants();
	}

	private setRestaurants(): void {
		this.setDay(this.layoutFilterService.filters.day() as api.WeekDay[]);
		this.setPeriodEffect(this.layoutFilterService.range());

		effect(
			() => {
				if (this.layoutFilterService?.filters?.restaurant()) {
					this.fetchDTT();
				}
			},
			{ injector: this.injector, allowSignalWrites: true }
		);

		effect(
			() => {
				this.setPeriodEffect(this.layoutFilterService.range());
			},
			{ injector: this.injector, allowSignalWrites: true }
		);

		effect(
			() => {
				this.sourceType = this.layoutFilterService.filters?.source();
				this.fetchDTT();
			},
			{ injector: this.injector, allowSignalWrites: true }
		);

		effect(
			() => {
				this.groupType = this.layoutFilterService.filters?.groupType();
				this.fetchDTT();
			},
			{ injector: this.injector, allowSignalWrites: true }
		);

		effect(
			() => {
				this.setDay(this.layoutFilterService?.filters?.day() as api.WeekDay[]);
			},
			{ injector: this.injector, allowSignalWrites: true }
		);
		const availableExportOptions: ExportOption[] = [
			{ label: ExportOptionType.PDF, selected: false, type: ExportOptionType.PDF },
			{ label: ExportOptionType.XLSX, selected: false, type: ExportOptionType.XLSX },
		];
		this.layoutFilterService.setAvailableExportOptions(availableExportOptions);
		this.layoutFilterService.export.pipe(takeUntilDestroyed(this.dr)).subscribe((selectedOptions: string[]) => {
			this._exportData(selectedOptions);
		});
	}

	private setDay(day: api.WeekDay[]): void {
		if (day && !equals(day, Array.from(this.weekDays))) {
			this.weekDays = new Set<api.WeekDay>(day);
			this.fetchDTT();
		}
	}

	private setPeriodEffect(range: LayoutRange): void {
		if (
			(range && this.dateRange.controls.from.value.toDateString() !== range?.from?.toJSDate()?.toDateString()) ||
			this.dateRange.controls.to.value.toDateString() !== range?.to?.toJSDate()?.toDateString()
		) {
			this.dateRange.setValue({
				from: range?.from?.toJSDate(),
				to: range?.to?.toJSDate(),
			});
			this.setPeriod(Range.Period);
		}
	}

	private _exportData(type: string[]) {
		for (const option of type) {
			switch (option) {
				case ExportOptionType.XLSX:
					this.exportXLSX(this.contentViewData());
					break;
			}
		}
	}
}
